import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";
import { TimeConfirmationUser } from "../../types";

type TimeConfirmationState = {
  isLoading: boolean;
  timeConfirmationUsersList: any[] | null;
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date" | "number";
  };
};

const initialState: TimeConfirmationState = {
  isLoading: true,
  timeConfirmationUsersList: [],
  orderedBy: {
    orderBy: "userName",
    criteria: "asc",
    dataType: "string",
  },
};

export const timeConfirmationResultSlice = createSlice({
  name: "timeConfirmationResult",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadTimeConfirmationInfo: (state, action) => {
      state.timeConfirmationUsersList = action.payload;
      state.timeConfirmationUsersList?.forEach((user: TimeConfirmationUser) => {
        user.difference = user.workedHours - user.expectedHours;
      });
      state.isLoading = false;
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date" | "number";
        criteria?: "asc" | "desc";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: action.payload.criteria ? action.payload.criteria : "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria = action.payload.criteria
          ? action.payload.criteria
          : state.orderedBy.criteria === "asc"
          ? "desc"
          : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, loadTimeConfirmationInfo, changeOrder } =
  timeConfirmationResultSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.timeConfirmationResult.isLoading;
};

export const SelectTimeConfirmationUsersList = ({
  timeConfirmationResult,
}: {
  timeConfirmationResult: TimeConfirmationState;
}) => {
  return {
    timeConfirmationUsersList: timeConfirmationResult.timeConfirmationUsersList,
  };
};

export const selectOrderCriteria = ({
  timeConfirmationResult,
}: {
  timeConfirmationResult: TimeConfirmationState;
}) => timeConfirmationResult.orderedBy;

export const selectOrderedBy = createSelector(
  [SelectTimeConfirmationUsersList, selectOrderCriteria],
  (timeConfirmationResult, orderCriteria) => {
    return {
      timeConfirmationUsersList:
        (timeConfirmationResult.timeConfirmationUsersList &&
          timeConfirmationResult.timeConfirmationUsersList.length > 0 &&
          sortDataBy(
            timeConfirmationResult.timeConfirmationUsersList,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null,
    };
  }
);

export default timeConfirmationResultSlice.reducer;

import { DateTime } from "luxon";
import { Role } from "../../types";
import { ExternalService } from "../../BillingModule/services/external";

import dayjs, { Dayjs } from "dayjs";
import dayjsBusinessTime from "dayjs-business-time";
import { NOW, getDateFromISOFormat } from "./dateUtils";

dayjs.extend(dayjsBusinessTime);

// Shared
export const THE_HUB = "The Hub";
export const TODAY = "Today";
export const THIS_WEEK = "This Week";
export const LAST_WEEK = "Last Week";
export const THIS_MONTH = "This Month";
export const LAST_MONTH = "Last Month";
export const LAST_PAY_PERIOD = "Last pay period";
export const THIS_PAY_PERIOD = "This pay period";
export const YEAR_TO_DATE = "Year to date";
export const LOCKED = "Locked";
export const APPROVED_TEXT = "Approved";
export const PENDING_TEXT = "Pending";
export const CLOSED_TEXT = "Closed";
export const COMPLETE_TEXT = "Complete";
export const RESOLVED_TEXT = "Resolved";
export const ACTIVE_TEXT = "Active";
export const INACTIVE_TEXT = "Inactive";
export const CANCELED_TEXT = "Canceled";
export const ORDINARY = "Ordinary";
export const BILLABLE = "Billable";
export const INTERNAL_PROJECT = "ITX Investment: Internal Project";
export const UNPAID_TIME_OFF = "Unpaid Time Off";
export const BENEFITS = "Benefits";
export const UNPAID = "Unpaid";
export const INVOICE_OWNERS_LABEL = "Invoice Owners";
export const ALL_PROJECTS_AND_WORK_ORDERS = "All Projects and Work Orders";
export const ALL_COST_CENTERS = "All Cost Centers";
export const ALL_PROJ_WO = "All Projects, All Work Orders";
export const ALL_WO_PROJ = "All Work Orders, All Projects";
export const ALL_CYCLES = "All Cycles";
export const ALL_CLIENTS = "All Clients";
export const ALL_STATUSES = "All Statuses";
export const ALL_OWNERS = "All Owners";
export const ALL_DEPARTMENTS = "All Departments";
export const ALL_USERS = "All Users";
export const NONE = "None";
export const HOLIDAY = "Holiday";
export const BAMBOO_HR = "BambooHR";
export const CLOUDFARE_ERROR_MESSAGE =
  "There is inappropriate text in the description of a time entry and it has not been recorded";
export const CLOUDFARE_ERROR =
  "This website is using a security service to protect itself from online attacks. The action you just performed triggered the security solution. There are several actions that could trigger this block including submitting a certain word or phrase, a SQL command or malformed data.";

// shared constant number
export const PROJ_PAID_TIME_OFF_ID = 233;
export const PROJ_UNPAID_TIME_OFF_ID = 405;
export const PROJ_NURSING_MOTHER_ID = 31557;
export const PROJ_MEAL_BREAK_ID = 24056;
export const TASKTYPE_UNPAID_TIME_OFF_ID = 42;
export const TASKTYPE_NURSING_MOTHER_ID = 31;
export const TASKTYPE_MEAL_BREAK_ID = 30;
export const TASKTYPE_HOLIDAY_ID = 33;

// closed project, task, work orders
export const closed_status = [CLOSED_TEXT, COMPLETE_TEXT, RESOLVED_TEXT, INACTIVE_TEXT, CANCELED_TEXT];

// billable and non billable ids for 'timeEntryTypeId'
export const billableIds = ["1", "3"];
export const nonBillableIds = ["2", "4", "5"];

// Asuite access
export const TIMESHEETS_ADMIN_BASIC_ACCESS = "Timesheets_Admin";
export const APROVED_ALL_BASIC_ACCESS = "Timesheets_ApproveAll_L1";
export const TIMESHEETS_BASIC_ACCESS = "Timesheets_BasicAccess";
export const REPORTS_BASIC_ACCESS = "Timesheets_Reports";
export const SUPER_ADMIN_BASIC_ACCESS = "SuperAdmin_BasicAccess";

// Enviroments
export const DEVELOPMENT = "development";
export const RELEASE = "preprod";
export const PRODUCTION = "production";

// Modules
export const NO_MODULE = "no-module";
export const BILLING_MODULE = "billing";
export const PRODUCTS_MODULE = "products";
export const ADMIN_MODULE = "admin";
export const TIMES_MODULE = "timesheets";

// Shared Routes
export const ROUTE_HOME = "/";
export const ROUTE_THEHUB_ADMIN = "/admin";
export const ROUTE_NOT_FOUND_PAGE = "/404-page-not-found";
export const ROUTE_FORBIDDEN_PAGE = "/forbidden-page";
export const ROUTE_LOG_OUT = "/log-out";
export const ROUTE_SAMPLE_REPORT = "/sample-report";
export const ROUTE_ACCESS_CONTROL_REPORT_BY_USER =
  "/access-control-report-by-user";
export const ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT =
  "/access-control-report-by-jira-project";
export const ROUTE_ACCESS_CONTROL_REPORT_BY_GITLAB_PROJECT =
  "/access-control-report-by-gitlab-project";

// Billing Routes
export const ROUTE_INVOICES_LIST = "/billing";
export const ROUTE_INVOICES_DETAIL = "/billing/invoices/:id";
export const ROUTE_INVOICES_SEARCH = "/billing/search";
export const ROUTE_INVOICES_FILTERS = "/billing/filters";
export const ROUTE_RECURRING_QUERY = "/billing/recurring";
export const ROUTE_PROFFESSIONAL_SERVICES_QUERY =
  "/billing/professional-services";
export const ROUTE_BILLABLE_ITEMS_RESULTS = "/billing/results";

// Products Routes
export const ROUTE_PRODUCTS_LIST = "/products";
export const ROUTE_DELETE_PRODUCT_PAGE = "/product-not-found";
export const ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT =
  "/products/reports/spotlightSummaryReport";
export const ROUTE_PRODUCT_ADVOCACY_SUMMARY_REPORT =
  "/products/reports/advocacySummaryReport";
export const ROUTE_PRODUCT_ADVOCACY_DETAIL_REPORT =
  "/products/reports/advocacyDetailReport";
export const ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT =
  "/products/reports/relationshipUpdatesReport";
export const ROUTE_PRODUCT_RELATIONSHIP_REVIEW_REPORT =
  "/products/reports/relationshipReviewReport";
export const ROUTE_PRODUCT_SPOTLIGHT = "/products/:productId/spotlight";
export const ROUTE_PRODUCT_SPOTLIGHT_HISTORY =
  "/products/:productId/spotlightHistory";
export const ROUTE_PRODUCT_KEYCONTACTS = "/products/:productId/keyContacts";
export const ROUTE_PRODUCT_NEW_KEYCONTACTS_DETAILS =
  "/products/:productId/keyContacts/addContact";
export const ROUTE_PRODUCT_KEYCONTACTS_DETAILS =
  "/products/:productId/keyContacts/:contactId";
export const ROUTE_PRODUCT_KEYCONTACTS_CONFIDENCE_HISTORY =
  "/products/:productId/keyContacts/:contactId/confidenceHistory";
export const ROUTE_PRODUCT_SUCCESSCRITERIA =
  "/products/:productId/successCriteria";

// Times Routes
export const ROUTE_TIMESHEETS = "/time";
export const ROUTE_TIMESHEETS_REPORTS = "/time/reports";
export const ROUTE_QUERY_TIMESHEETS = "/time/query";
export const ROUTE_QUERY_RESULTS_TIMESHEETS = "/time/query/results";
export const ROUTE_APPROVE_TIME_TIMESHEETS = "/time/approve-time";
export const ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER =
  "/time/approve-time/by-user";
export const ROUTE_BAMBOO_TIME_OFF_REPORT = "/time/reports/bambooTimeOffReport";
export const ROUTE_TIME_CONFIRMATION_REPORT =
  "/time/reports/TimeConfirmationReport";
export const ROUTE_TIME_CONFIRMATION_RESULTS =
  "/time/reports/TimeConfirmationReport/results";
  export const ROUTE_WHO_IS_OUT_REPORT =
  "/time/reports/WhoIsOutReport";

// other router
export const ROUTE_PROJECTS = "/projects";
export const ROUTE_WORK_ORDERS = "/work-orders";
export const ROUTE_BUS_DEV = "/bus-dev";
export const ROUTE_INTELLIGENCE = "/intelligence";

export const PENDING = 1;
export const APPROVED = 2;
export const EXPORTED = 3;
export const RESET = 4;
export const VOID = 5;

// Roles - All modules
export const ROLE_SUPER_ADMIN = "Super Admin";
export const ROLE_PRICE_MANAGER = "Price Manager";

// Roles - Billing Module
export const ROLE_ACCOUNTING_ADMINISTRATOR = "Accounting Administrator";
export const ROLE_ACCOUNTING_COORDINATOR = "Accounting Coordinator";
export const ROLE_INVOICE_APPROVER_OWNER = "Invoice Approver - Owner";
export const ROLE_RECURRING_BILLING_OWNER = "Recurring Billing Owner";
export const ROLE_SUPERVISOR = "Supervisor";
export const ROLE_INVOICE_VIEWER = "Invoice Viewer";

// Roles - Products module
export const ROLE_ALL_PRODUCTS_VIEWER = "All Products Viewer";
export const ROLE_ALL_PRODUCTS_ADMIN = "All Products Admin";
export const ROLE_ENGAGEMENT_LEAD = "Engagement Lead";
export const ROLE_ENGAGEMENT_MANAGER = "Engagement Manager";
// Automatics Roles - these should not be used
export const ROLE_PRODUCT_VIEWER = "Product Viewer";
export const ROLE_PRODUCT_EDITOR = "Product Editor";
export const ROLE_PRODUCT_ADMIN = "Product Admin";

// Roles - Times Module
export const ROLE_TIMESHEETS_ADMIN = "Time Admin";
export const ROLE_TIMESHEETS_TEAM_MEMBER = "Team Member";

// Automatic Roles
export const ROLE_PROJECT_OWNER = "Project Owner";

// Billing policies
export const ROLE_NOT_OWNERS: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_SUPERVISOR,
];

export const ROLE_BILLING_MODULE_PERFORM_SEARCH: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_SUPERVISOR,
  ROLE_INVOICE_VIEWER,
];

export const ROLE_BILLING_MODULE: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_INVOICE_APPROVER_OWNER,
  ROLE_RECURRING_BILLING_OWNER,
  ROLE_SUPERVISOR,
  ROLE_INVOICE_VIEWER,
];

export const ROLE_BILLING_MODULE_PERFORM_ACTION: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ACCOUNTING_ADMINISTRATOR,
  ROLE_ACCOUNTING_COORDINATOR,
  ROLE_INVOICE_APPROVER_OWNER,
  ROLE_RECURRING_BILLING_OWNER,
  ROLE_SUPERVISOR,
];

export const ROLE_BILLING_MODULE_EXPORT_INVOICES: Role["name"][] = [
  ROLE_INVOICE_VIEWER,
];

// Products policies
export const PRODUCTS_CREATOR: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ALL_PRODUCTS_ADMIN,
  ROLE_ENGAGEMENT_MANAGER,
];

export const PRODUCTS_DELETE: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_ALL_PRODUCTS_ADMIN,
];

// Admin access
export const ADMIN_ACCESS: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_PRICE_MANAGER,
];

// Time admin access
export const TIME_SUPER_ADMIN_ACCESS: Role["name"][] = [
  ROLE_SUPER_ADMIN,
  ROLE_TIMESHEETS_ADMIN,
];

// Time basic access
export const TIME_ADMIN_ACCESS: Role["name"][] = [
  ROLE_SUPERVISOR,
  ROLE_PROJECT_OWNER,
  ROLE_TIMESHEETS_ADMIN,
  ROLE_SUPER_ADMIN,
];

// Time basic access
export const TIME_BASIC_ACCESS: Role["name"][] = [
  ROLE_TIMESHEETS_TEAM_MEMBER,
  ROLE_SUPERVISOR,
  ROLE_PROJECT_OWNER,
  ROLE_TIMESHEETS_ADMIN,
  ROLE_SUPER_ADMIN,
];

// Helper to know if have a billing role
export const hasBillingRole = (userRoles: Role[] | null | undefined) => {
  let result =
    userRoles?.some((role) =>
      Array.isArray(ROLE_BILLING_MODULE)
        ? ROLE_BILLING_MODULE.find(
            (allowedBillingRoles) => allowedBillingRoles === role.name
          )
        : ROLE_BILLING_MODULE === role.name
    ) || false;
  return result;
};

// Business days
const holidays: string[] = [];
const loadData = async () => {
  const data = await ExternalService.getYearHolidays("US", NOW.year);
  data.forEach((element) => holidays.push(getDateFromISOFormat(element.date)));
  // Add holidays to dayjs
  dayjs.setHolidays(holidays);
  let day: Dayjs = dayjs(NOW.startOf("month").toISODate());
  // Should be the first workable day
  while (!day.isBusinessDay() || day.isHoliday()) {
    day = day.addBusinessDays(1);
  }
  const nextBillingDate: Dayjs = day.addBusinessDays(12);
  THIS_MONTHS_BILLING = DateTime.fromJSDate(nextBillingDate.toDate());
};
loadData();
// Should load this data inside 'loadData' function
export let THIS_MONTHS_BILLING: DateTime;

import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ROUTE_QUERY_RESULTS_TIMESHEETS,
  ROUTE_TIME_CONFIRMATION_REPORT,
  ROUTE_TIME_CONFIRMATION_RESULTS,
  TIME_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { checkDataIsLoaded, transformValue } from "../utils/timesUtils";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { verifyLinkAccess } from "../../SharedModule/utils/accessUtils";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Spinner } from "../../SharedModule/components/Spinner";
import Footer from "../../SharedModule/components/Footer";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { keepFilters } from "../redux/reducers/timeConfirmationReport.reducer";
import {
  dateFormatEntryEdit,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import { getDiffBetweenDates } from "../../SharedModule/utils/dateUtils";
import { useTimesConfirmationResults } from "../hooks/useTimesConfirmationResults";
import {
  changeOrder,
  selectOrderCriteria,
} from "../redux/reducers/timeConfirmationResults.reducer";
import { TimeConfirmationUser } from "../types";
import {
  SelectInitialStateFilters,
  setFilters,
} from "../redux/reducers/timesQuery.reducer";

export const TimesConfirmationResultsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortBy = useSelector(selectOrderCriteria);
  const initialStatefilters = useSelector(SelectInitialStateFilters);

  const {
    isLoading,
    MetaTimesInfo,
    filters,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isTimeAdmin,
    isLoadingUserInfo,
    timeConfirmationUsersList,
  } = useTimesConfirmationResults();

  useEffect(() => {
    document.title = "The Hub - Query Results";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    dispatch(setActiveTab(ROUTE_TIME_CONFIRMATION_RESULTS));

    return () => {
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_TIME_CONFIRMATION_REPORT) {
        dispatch(keepFilters(false));
      }
    };
  }, [trackPageView, isTimeAdmin]);

  const backToQuery = () => {
    dispatch(keepFilters(true));
  };

  const handleClick = (user: TimeConfirmationUser) => {
    dispatch(
      setFilters({
        ...initialStatefilters,
        startDate: filters.startDate,
        endDate: filters.endDate,
        user: [{ label: user.userName, value: user.userId }],
      })
    );
    navigate(ROUTE_QUERY_RESULTS_TIMESHEETS);
  };

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && (
          <div className="content">
            <div className="mt-2 button-header">
              <Link
                className="back-button-container text-decoration-none"
                to={ROUTE_TIME_CONFIRMATION_REPORT}
                onClick={() => backToQuery()}
              >
                <BiLeftArrowAlt className="back-button-arrow" />
                <span className="back-button-text">Back to Query</span>
              </Link>
            </div>
            <div className="d-flex flex-wrap mb-1">
              <h2 className="flex-fill">Time Confirmation</h2>
            </div>
            <div className="flex-container flex-wrap">
              <div className="col-lg-4 col-12 d-inline-flex mb-3">
                <div className="col-4">From</div>
                <CheckSkeleton isLoading={false} classes="col-7">
                  <div className="col-7 fw-bold">{`${dateFormatEntryEdit(
                    filters.startDate
                  )} - ${dateFormatEntryEdit(filters.endDate)}`}</div>
                </CheckSkeleton>
              </div>
              <div className="col-lg-4 col-12 d-inline-flex mb-3">
                <div className="col-4">Countries</div>
                <CheckSkeleton isLoading={false} classes="col-7">
                  <div className="col-7 fw-bold">
                    {filters.allCountries ? "All Countries" : "USA"}
                  </div>
                </CheckSkeleton>
              </div>
              <div className="col-lg-4 col-12 d-inline-flex mb-3">
                <div className="col-4">Working days</div>
                <CheckSkeleton isLoading={false} classes="col-7">
                  <div className="col-7 fw-bold">
                    {getDiffBetweenDates(filters.startDate, filters.endDate)}
                  </div>
                </CheckSkeleton>
              </div>
              <div className="col-lg-12 col-12 d-inline-flex mb-3">
                <div className="col-1">Cost Centers</div>
                <CheckSkeleton isLoading={false} classes="col-7">
                  <div className="col-10 fw-bold ms-5">
                    {transformValue("costCentersIDs", filters.costCentersIDs)}
                  </div>
                </CheckSkeleton>
              </div>
            </div>

            <hr className="header-separator mt-4" />

            {isLoading && (
              <Spinner
                style={{
                  marginLeft: "45%",
                  marginTop: "10%",
                }}
              />
            )}

            {!isLoading && (
              <table
                className="grey-table-header-background time-confirmation table my-5"
                style={{ width: "100%" }}
              >
                <thead className="align-middle sticky-header sticky">
                  <tr>
                    <th
                      className={`border-left border-right border-top fw-500 py-3 ps-4 column-20 ${
                        sortBy.orderBy === "userName"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="userName"
                      id="UserName"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "userName",
                            dataType: "string",
                          })
                        )
                      }
                    >
                      Person
                    </th>
                    <th
                      className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                        sortBy.orderBy === "costCenter"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="costCenter"
                      id="CostCenter"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "costCenter",
                            dataType: "string",
                          })
                        )
                      }
                    >
                      Cost Center
                    </th>
                    <th
                      className={`border-top border-right fw-500 py-3 ps-4 column-15 ${
                        sortBy.orderBy === "country"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="country"
                      id="Country"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "country",
                            dataType: "string",
                          })
                        )
                      }
                    >
                      Country
                    </th>
                    <th
                      className={`border-top border-right fw-500 py-3 ps-4 column-15 ${
                        sortBy.orderBy === "hoursPerDay"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      key="hoursPerDay"
                      id="HoursPerDay"
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "hoursPerDay",
                            dataType: "number",
                          })
                        )
                      }
                    >
                      Hours per day
                    </th>
                    <th
                      className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                        sortBy.orderBy === "workedHours"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "workedHours",
                            dataType: "number",
                          })
                        )
                      }
                      key="workedHours"
                      id="Worked hours"
                    >
                      Worked hours / Expected hours
                    </th>
                    <th
                      className={`border-right border-top fw-500 py-3 ps-4 column-20 ${
                        sortBy.orderBy === "difference"
                          ? "sorted-" + sortBy.criteria
                          : ""
                      }`}
                      onClick={() =>
                        dispatch(
                          changeOrder({
                            orderBy: "difference",
                            dataType: "number",
                          })
                        )
                      }
                      id="Difference"
                      key="difference"
                    >
                      Difference
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {timeConfirmationUsersList &&
                    timeConfirmationUsersList.map(
                      (user: TimeConfirmationUser, index: number) => {
                        return (
                          <tr
                            key={user.userId}
                            style={{ verticalAlign: "middle" }}
                            className={index % 2 === 0 ? "even-row" : "odd-row"}
                          >
                            <td className="py-3 ps-4 fw-500">
                              <div className="td-text">{user.userName}</div>
                            </td>
                            <td className="py-3 ps-4 fw-500">
                              <div className="td-text">{user.costCenter}</div>
                            </td>
                            <td className="py-3 ps-4 fw-500">
                              <div className="td-text">{user.country}</div>
                            </td>
                            <td className="py-3 ps-4 fw-500">
                              <div className="td-text">
                                {quantityFormat(user.hoursPerDay)}
                              </div>
                            </td>
                            <td className="py-3 ps-3 fw-500">
                              <div className="d-flex justify-content-between text-start">
                                <div
                                  className={`me-3 text-end ${
                                    user.workedHours > 0
                                      ? "td-task-link"
                                      : "td-text"
                                  }`}
                                  style={{ width: "33%" }}
                                  onClick={() =>
                                    user.workedHours > 0
                                      ? handleClick(user)
                                      : null
                                  }
                                >
                                  {quantityFormat(user.workedHours)}
                                </div>
                                <div
                                  className="td-text me-2"
                                  style={{ width: "6%" }}
                                >
                                  /
                                </div>
                                <div
                                  className="td-text me-2"
                                  style={{ width: "47%" }}
                                >
                                  {quantityFormat(user.expectedHours)}
                                </div>
                              </div>
                            </td>
                            <td className="py-3 ps-4 fw-500">
                              <div
                                className={`td-text ${
                                  user.difference < 0
                                    ? "red-number"
                                    : user.difference > 0
                                    ? "green-number"
                                    : ""
                                }`}
                              >
                                {user.difference > 0
                                  ? "+" + user.difference
                                  : user.difference}
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                </tbody>
              </table>
            )}

            <Footer />
          </div>
        )}
    </>
  );
};

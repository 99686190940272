import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useRef, useState } from "react";
import Footer from "../../SharedModule/components/Footer";
import { Calendar } from "../../SharedModule/components/Calendar";
import {
  BiChevronLeft,
  BiChevronRight,
  BiListUl,
  BiGridAlt,
  BiCalendar,
  BiLockAlt,
  BiInfoCircle,
  BiEditAlt,
  BiTrash,
  BiError,
  BiErrorCircle,
} from "react-icons/bi";
import {
  dateFormatEntryEdit,
  nameDayDateFormat,
  numDayShortMonthDateFormat,
  numDayShortMonthWithYearDateFormat,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import { Select } from "../../SharedModule/components/Select";
import {
  BILLABLE,
  LOCKED,
  nonBillableIds,
  THIS_WEEK,
  UNPAID,
  PROJ_MEAL_BREAK_ID,
  PROJ_PAID_TIME_OFF_ID,
  PROJ_UNPAID_TIME_OFF_ID,
  ROUTE_TIMESHEETS,
  NONE,
  TASKTYPE_UNPAID_TIME_OFF_ID,
  TASKTYPE_MEAL_BREAK_ID,
  CLOUDFARE_ERROR,
  CLOUDFARE_ERROR_MESSAGE,
  TODAY,
  CLOSED_TEXT,
  RESOLVED_TEXT,
  ACTIVE_TEXT,
  BAMBOO_HR,
} from "../../SharedModule/utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { EntryHoursBox } from "../components/EntryHoursBox";
import { useTimes } from "../hooks/useTimes";
import {
  CustomWeekDay,
  DayInfo,
  EntryListView,
  RowSheetView,
  Subtask,
  TaskSheetView,
  TimeEntryForm,
  TimeEntryPostForm,
  TimeEntrySheetView,
} from "../types";
import {
  addEntry,
  addInvalidEntriesFromEndpoint,
  changeListView,
  addInvalidEntry,
  resetEntries,
  setLoading,
  setSubmitted,
  resetInvalidEntries,
  removeValidEntry,
} from "../redux/reducers/times.reducer";
import { SummaryTotals } from "../components/SummaryTotals";
import {
  getDateFromISOFormat,
  getPreviousWorkableDay,
  getShortDayName,
  getWeekFullDate,
  isDateAfterToday,
  isHoliday,
  isSameDay,
  isToday,
  getPrevSunday,
  getNextWeekDay,
  YESTERDAY,
  isYesterday,
} from "../../SharedModule/utils/dateUtils";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { DayTotals } from "../components/DayTotals";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalTimeEntry } from "../components/ModalTimeEntry";
import { ModalDeleteTimeEntry } from "../components/ModalDeleteTimeEntry";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import {
  getCategoryId,
  getCellClassTodayClickable,
  getValueFromSelect,
  navigateTo,
  sumHoursWorked,
  setTimeEntryTypeIdforRequest,
  concatProjectsWorkOrders,
  checkMealBreakOrNursingMother,
  checkDataIsLoaded,
  navigateToFromSheetView,
  isObjectEmpty,
  isEntryTimeOff,
  isDateValidToEnterTime,
  iconEditEnabled,
  iconDeleteEnabled,
  isClosed,
} from "../utils/timesUtils";
import { TimesheetsService } from "../services/timesheets";
import { selectUserAssignees } from "../redux/reducers/metaTimes.reducer";
import { ModalMoreInfoTimeEntry } from "../components/ModalMoreInfoTimeEntry";
import { useTopNavigationInfo } from "../../SharedModule/hooks/useTopNavigationInfo";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { newUuid } from "../../SharedModule/utils/uuid";
import { alreadyExistMealBreak } from "../utils/mealBreakHours";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../../SharedModule/components/NoContent";
import projectIcon from "./../../styles/legacy/project-icon.svg";
import workorderIcon from "./../../styles/legacy/workorder-icon.svg";

export const TimesPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  const today = new Date();

  const yesterday = DateTime.local(
    YESTERDAY.year,
    YESTERDAY.month,
    YESTERDAY.day
  ).toISO({
    includeOffset: false,
  });

  const usersAssignees = useSelector(selectUserAssignees);
  const { activeUserId, activeUserName, activeUserEmail } =
    useSelector(selectUserRoles);

  // when user is admin, should be seleted in users dropdown
  let firstLoadActualUser = usersAssignees?.filter(
    (user) => user.value === activeUserId
  );

  const [pageDate, setPageDate] = useState(new Date());
  const [showedWeek, setShowedWeek] = useState(THIS_WEEK);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [actualUser, setActualUser] = useState(
    firstLoadActualUser
      ? firstLoadActualUser[0]
      : { value: activeUserId, label: activeUserName, email: activeUserEmail }
  );
  const [activeEntry, setActiveEntry] = useState<
    EntryListView | TimeEntrySheetView
  >(Object);
  const [entryActions, setEntryActions] = useState(undefined);

  const {
    projectList,
    selectProjectOrWorkOrder,
    timeEntriesList,
    timeEntriesSheetView,
    summary,
    scheduledHours,
    holidays,
    entryList,
    isTimeAdmin,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    isListView,
    isLoading,
    MetaTimesInfo,
    loadTimesPageInfo,
    missingTime,
  } = useTimes(
    actualUser ? actualUser.value : activeUserId,
    pageDate,
    actualUser ? actualUser.email : activeUserEmail
  );

  // sheet view totals
  let totalSheetDaysHours: number[] = [0, 0, 0, 0, 0, 0, 0];

  // this array will contain days(string) when time task is unpaid time off and total hours are equals to scheduledHours
  let arrayCompleteDayTimeOff: string[] = [];

  // check if a unpaid time off time entries is equal to eight hours
  if (timeEntriesSheetView && timeEntriesSheetView.length > 0) {
    timeEntriesSheetView.forEach((row: RowSheetView) => {
      Object.keys(row.dailyDetails).forEach((day: string) => {
        if (
          isEntryTimeOff(row) &&
          row.dailyDetails[day].hoursWorked === scheduledHours &&
          scheduledHours > 0
        ) {
          arrayCompleteDayTimeOff.push(getDateFromISOFormat(day));
        }
      });
    });
  }

  const { loadInfo } = useTopNavigationInfo();

  useEffect(() => {
    document.title = "The Hub - Enter Time";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    dispatch(setActiveTab(ROUTE_TIMESHEETS));

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    // From Header component
    eventHandler.on("changeToSheetView", (data) => {
      setToday();
      if (data.isSheetView) {
        setIsListWiew(false);
      } else {
        setIsListWiew(true);
      }
    });

    return () => {
      eventHandler.remove("changeToSheetView", null);
    };
  }, [trackPageView, isTimeAdmin]);

  const setToday = () => {
    let today = new Date();
    pageDate.setFullYear(today.getFullYear());
    pageDate.setMonth(today.getMonth());
    pageDate.setDate(today.getUTCDate());
    setPageDate(today);
    calculateWeek(today);
    resetPage();
  };

  const updateDateByCalendar = (date: Date) => {
    pageDate.setFullYear(date.getFullYear());
    pageDate.setMonth(date.getMonth());
    pageDate.setDate(date.getUTCDate());
    setPageDate(date);
    calculateWeek(date);
    resetPage();
  };

  const addOneDay = () => {
    let newDate = pageDate.setDate(pageDate.getDate() + 1);
    setPageDate(new Date(newDate));
    calculateWeek(new Date(newDate));
    resetPage();
  };

  const removeOneDay = () => {
    let newDate = pageDate.setDate(pageDate.getDate() - 1);
    setPageDate(new Date(newDate));
    calculateWeek(new Date(newDate));
    resetPage();
  };

  const [showCardError, setShowCardError] = useState(false);

  const [isListWiew, setIsListWiew] = useState(isListView);

  // logic to disabled main calendar
  let previousWorkableDay = getPreviousWorkableDay(1, holidays);
  let lastWorkableDay: any = new Date(
    getNextWeekDay(6, new Date()).setHours(23, 59, 59)
  );
  // only for add one day arrow
  let lastWorkableDayForArrow: any = new Date(getNextWeekDay(6, new Date()));

  const hasContent = () => {
    return (
      timeEntriesList.filter((entry) => isSameDay(entry.entryDate, pageDate))
        .length > 0
    );
  };

  let todayEntriesTotalHours = 0;
  let yesterdayEntriesTotalHours = 0;
  let todayHasMealBreak = false;
  let yesterdayHasMealBreak = false;

  timeEntriesList
    .filter((entry) => isSameDay(entry.entryDate, today))
    .forEach((entry: EntryListView) => {
      todayEntriesTotalHours += entry.hours;
      if (
        entry.entityId === PROJ_MEAL_BREAK_ID ||
        (entry.entityId === PROJ_UNPAID_TIME_OFF_ID &&
          entry.taskTypeId === TASKTYPE_MEAL_BREAK_ID)
      ) {
        todayHasMealBreak = true;
      }
    });
  timeEntriesList
    .filter((entry) => isSameDay(entry.entryDate, new Date(yesterday)))
    .forEach((entry: EntryListView) => {
      yesterdayEntriesTotalHours += entry.hours;
      if (
        entry.entityId === PROJ_MEAL_BREAK_ID ||
        (entry.entityId === PROJ_UNPAID_TIME_OFF_ID &&
          entry.taskTypeId === TASKTYPE_MEAL_BREAK_ID)
      ) {
        yesterdayHasMealBreak = true;
      }
    });

  // will replace with info endpoint
  let beforeYesterdayMealBreakReminders = false;

  let todayMealBreakReminders =
    todayEntriesTotalHours >= 6 && !todayHasMealBreak && isToday(today);
  let yesterdayMealBreakReminders =
    yesterdayEntriesTotalHours >= 6 &&
    !yesterdayHasMealBreak &&
    isYesterday(new Date(yesterday));

  // THead for sheet view
  // To load tr and td for sheets view table
  let actualWeek: CustomWeekDay[] = [];
  for (let i = 0; i < 7; i++) {
    actualWeek.push({
      day: getShortDayName(i),
      numDay: getNextWeekDay(i, pageDate).getUTCDate(),
      fullDate: getNextWeekDay(i, pageDate),
      isToday: isToday(getNextWeekDay(i, pageDate)),
    });
  }

  const calculateWeek = (newDate: Date) => {
    let sunday = getPrevSunday(newDate);
    let saturday = getNextWeekDay(6, newDate);
    if (sunday < today && today < saturday) {
      if (showedWeek !== THIS_WEEK) {
        setShowedWeek(THIS_WEEK);
        loadTimesPageInfo(newDate);
        return true;
      }
    } else {
      let newActualWeek = `${
        sunday.getFullYear() === today.getFullYear()
          ? numDayShortMonthDateFormat(sunday.toISOString())
          : numDayShortMonthWithYearDateFormat(sunday.toISOString())
      } - ${
        saturday.getFullYear() === today.getFullYear()
          ? numDayShortMonthDateFormat(saturday.toISOString())
          : numDayShortMonthWithYearDateFormat(saturday.toISOString())
      }`;
      if (showedWeek !== newActualWeek) {
        setShowedWeek(newActualWeek);
        loadTimesPageInfo(newDate);
        return true;
      }
    }
    return false;
  };

  // add new empty entry
  const addNewEntry = () => {
    dispatch(addEntry(pageDate));
  };

  // check in entries childs if at least one is valid, set save all button enabled
  const checkEntriesStatus = () => {
    setSaveButtonDisabled(false);
  };

  // check in entries childs if at least one is valid, set save all button enabled
  const checkEntriesValidations = (id: string) => {
    let allValid = true;
    entryList.forEach((element) => {
      if (
        element.entryId !== id &&
        element.isEntryTouched &&
        !element.isEntryValid
      ) {
        allValid = false;
      }
    });
    if (allValid) {
      setShowCardError(false);
      dispatch(setSubmitted(false));
    }
  };

  const isEntryFormTimeOffValid = (entry: TimeEntryForm) => {
    let isTimeoff =
      (entry.entryProjectWorkOrder &&
        entry.entryProjectWorkOrder.value === PROJ_PAID_TIME_OFF_ID) ||
      (entry.entryProjectWorkOrder &&
        entry.entryProjectWorkOrder.value === PROJ_UNPAID_TIME_OFF_ID &&
        entry.entryTaskType.value === TASKTYPE_UNPAID_TIME_OFF_ID);
    return (
      !isTimeoff ||
      (isTimeoff && activeUserId !== actualUser.value && isSuperTimeAdmin)
    );
  };

  // this function only checks if project is unpaid time off
  const checkValidDateForUnpaidTimeOff = (entry: any) => {
    let response = false;
    if (entry.entryDate >= yesterday) {
      response = true;
    } else if (isSuperTimeAdmin) {
      response = true;
    }
    return response;
  };

  // should update entryList with entries valid and call post endpoint
  const submitEntries = () => {
    setShowCardError(false);
    dispatch(setSubmitted(true));
    dispatch(resetInvalidEntries());

    let olderDate = null;

    let request: TimeEntryPostForm[] = [];
    entryList.forEach((entry) => {
      if (entry.isEntryTouched) {
        // should not add 2 meal break in same day, also will be BE validation
        if (
          entry.isEntryValid &&
          !alreadyExistMealBreak(entry, timeEntriesList) &&
          isEntryFormTimeOffValid(entry) &&
          // not allow to enter unpaid time off after yesterday if is normal user or supervisor for himself
          (entry.entryProjectWorkOrder.value === PROJ_UNPAID_TIME_OFF_ID
            ? checkValidDateForUnpaidTimeOff(entry)
            : true) &&
          !request.some(
            (e) =>
              (entry.entryProjectWorkOrder.value === PROJ_MEAL_BREAK_ID &&
                entry.entryDate === e.entryDate) ||
              (entry.entryProjectWorkOrder.value === PROJ_UNPAID_TIME_OFF_ID &&
                entry.entryTaskType.value === TASKTYPE_MEAL_BREAK_ID &&
                entry.entryDate === e.entryDate)
          )
        ) {
          // save older date of valid entries to redirect after save
          olderDate =
            olderDate && olderDate < entry.entryDate
              ? olderDate
              : entry.entryDate;

          request.push({
            userId: actualUser ? actualUser.value : activeUserId,
            entryDate: entry.entryDate,
            hours: entry.entryHours,
            taskTypeId: entry.entryTaskType.value,
            description:
              entry.entryDescription.length > 0 ? entry.entryDescription : NONE,
            entityId: entry.entryProjectWorkOrder.value,
            timeEntryCategoryId: getCategoryId(
              projectList,
              entry.entryProjectWorkOrder.value
            ),
            timeEntryTypeId: setTimeEntryTypeIdforRequest(entry),
            timeEntrySourceId: 1,
            nonBillableReasonId: entry.entryIsBillable
              ? null
              : entry.entryNonBillableReason.value,
          });
          dispatch(removeValidEntry({ entry: entry }));
        } else {
          dispatch(addInvalidEntry({ entry: entry }));
          setShowCardError(true);
        }
      }
    });

    if (request.length > 0) {
      dispatch(setLoading(true));

      // take amount of save result for each entry
      let saveWithoutErrors: number = 0;
      let dontSave: number = 0;
      let catchError: number = 0;

      request.forEach(async (entry) => {
        try {
          const response = await TimesheetsService.addTimesEntries([entry]);
          if (response.data.data && response.data.data.length > 0) {
            dispatch(
              addToast({ mode: "error", message: response.data.message })
            );
            dispatch(
              addInvalidEntriesFromEndpoint({
                list: response.data.data,
                meta: MetaTimesInfo,
              })
            );
            dontSave += 1;
          } else {
            dispatch(
              addToast({ mode: "success", message: response.data.message })
            );
            saveWithoutErrors += 1;
          }
        } catch (err: any) {
          // to keep time entry in page
          dispatch(
            addInvalidEntriesFromEndpoint({
              list: [{ error: CLOUDFARE_ERROR_MESSAGE, timeEntry: entry }],
              meta: MetaTimesInfo,
            })
          );

          dispatch(
            addToast({
              mode: "error",
              message:
                err.response.status === 403 &&
                err.response.data.includes(CLOUDFARE_ERROR)
                  ? CLOUDFARE_ERROR_MESSAGE
                  : err.response.data.error ||
                    "An error ocurred trying to perform operation",
            })
          );
          catchError += 1;
        }
        // after last call ended
        if (saveWithoutErrors + dontSave + catchError === request.length) {
          if (saveWithoutErrors) {
            if (dontSave || catchError) setShowCardError(true);
            setSaveButtonDisabled(true);
            dispatch(setSubmitted(false));
            refreshPage(olderDate ? olderDate : pageDate);
          } else {
            setShowCardError(true);
            refreshPage(olderDate ? olderDate : pageDate);
          }
        }
      });
    }
  };

  const changeView = (islistView: boolean) => {
    dispatch(changeListView(islistView));
    setIsListWiew(islistView);
    resetPage();
  };

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
    setAddMealBreakFromListView(false);
  };

  // Modal Delete config
  const modalDeleteRef = useRef();
  const showDeleteModal = () => {
    (modalDeleteRef.current as any).show(true);
  };
  const closeDeleteModal = () => {
    (modalDeleteRef.current as any).show(false);
  };

  // Modal More Info config
  const modalMoreInfoRef = useRef();
  const showMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(true);
  };
  const closeMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(false);
  };

  const deleteEntry = (entry: EntryListView | TimeEntrySheetView) => {
    setActiveEntry(entry);
    showDeleteModal();
  };

  const deleteEntryModal = async () => {
    closeDeleteModal();
    if (!isListView) {
      closeModal();
    }
    dispatch(setLoading(true));
    await TimesheetsService.deleteTimesEntry(activeEntry.timeEntryId)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));
        resetPage();
        refreshPage(pageDate);
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  // Update header hours is neccesary and bring new time entries data
  const refreshPage = (date: any) => {
    // if user is not admin or is admin and is adding own hours
    if (!isTimeAdmin || activeUserId === actualUser.value) {
      loadInfo();
    }
    let newDate = typeof date === "string" ? new Date(date) : date;
    setPageDate(newDate);
    let isWeekChanged = calculateWeek(newDate);
    // to avoid call twice, loadTimesPageInfo called inside calculateWeek
    if (!isWeekChanged) {
      loadTimesPageInfo(newDate);
    }
  };

  const editEntry = (entry: EntryListView) => {
    setEntryInitalInfo(null);
    // improvment to avoid issue with date
    let actualEntry = {
      ...entry,
      entryDate: getDateFromISOFormat(entry.entryDate) + "T03:00:00Z",
    };
    setActiveEntry(actualEntry);
    showModal();
  };

  const [entryInitalInfo, setEntryInitalInfo] = useState<any>(null);
  const [actualTimeEntries, setActualTimeEntries] = useState<any>([]);

  const actualProjectsWorkOrders = concatProjectsWorkOrders(
    selectProjectOrWorkOrder
  );

  // EDIT TIME ENTRY IN TASK - categoryId = 2
  const editEntrySheetView = (
    day: DayInfo,
    taskId: number,
    row: RowSheetView
  ) => {
    let proj: any = getValueFromSelect(actualProjectsWorkOrders, taskId);

    let modalDate: string = getWeekFullDate(
      actualWeek,
      day.dayNumber
    ).toISOString();
    // entryInitalInfo => set info when user want to add time entry from sheet view with date and task type
    setEntryInitalInfo({
      taskType: proj?.defaultTaskTypeId,
      entityId: row.entityStatus === ACTIVE_TEXT ? taskId : row.projectId,
      modalDate,
      categoryId: row.categoryId,
      entityName: row.entityName,
    });
    // No timeEntries is add
    if (!day.timeEntries) {
      setActualTimeEntries([]);
      showModal();
    } else {
      // modal to edit time entries with date and task
      setActualTimeEntries(day.timeEntries);
      setActiveEntry(day.timeEntries[0]);
      showModal();
    }
  };

  // EDIT TIME ENTRY IN PROJECT OR WORK ORDER - categoryId = 1 || 3
  const editEntryWithoutTaskSheetView = (
    dayNumber: number,
    row: any,
    timeEntries: any
  ) => {
    let newEntityId: number = 0;
    Object.keys(row.dailyDetails).forEach((day) => {
      if (row.dailyDetails[day].hoursWorked > 0) {
        newEntityId = row.dailyDetails[day].timeEntries[0].entityId;
      } else {
        newEntityId = row.entityId;
      }
    });
    let modalDate: string = getWeekFullDate(
      actualWeek,
      dayNumber
    ).toISOString();
    // entryInitalInfo => set info when user want to add time entry from sheet view with date and task type
    setEntryInitalInfo({
      entityId: newEntityId,
      modalDate,
      categoryId: row.categoryId,
      entityName: row.entityName,
    });
    // No timeEntries is add
    if (timeEntries.length === 0) {
      setActualTimeEntries([]);
      showModal();
    } else {
      // modal to edit time entries with date and task
      setActualTimeEntries(timeEntries);
      setActiveEntry(timeEntries[0]);
      showModal();
    }
  };

  const addNewEntrySheetView = () => {
    setEntryInitalInfo(null);
    setActualTimeEntries([]);
    showModal();
  };

  const showMoreInfo = async (entry: EntryListView) => {
    await TimesheetsService.getTimeEntryInfo(entry.timeEntryId)
      .then((response) => {
        if (response.data.data.length > 0) {
          setEntryActions(response.data.data);
          showMoreInfoModal();
        } else {
          dispatch(
            addToast({ mode: "error", message: "Time entry was not found" })
          );
        }
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  // when row has info in dailyDetails (could have task too - unpaid time off)
  const isTimeEntryClickable = (
    day: string,
    entries: any,
    infoDay: RowSheetView,
    isUnpaidTimeOffRow: boolean // is only for row with hours (unpaid time off), should be clickable. Then modal logic will avoid unpaid task
  ) => {
    let checkIsHoliday = isHoliday(new Date(day), holidays);
    if (
      // paid time off for himself without task
      (infoDay.entityId === PROJ_PAID_TIME_OFF_ID &&
        infoDay.dailyDetails[day].timeEntries.length === 0 &&
        activeUserId === actualUser.value) ||
      // paid time off for others not super admins without task
      (infoDay.entityId === PROJ_PAID_TIME_OFF_ID &&
        infoDay.dailyDetails[day].timeEntries.length === 0 &&
        activeUserId !== actualUser.value &&
        !isSuperTimeAdmin) ||
      // ASUITE11-2512: not add or edit time entries if are closed/resolved/inactive/complete/canceled except super admin
      (isClosed(infoDay.entityStatus) &&
        (infoDay.categoryId === 3 || infoDay.categoryId === 1) &&
        entries.length === 0) ||
      (isClosed(infoDay.entityStatus) &&
        infoDay.categoryId === 2 &&
        infoDay.tasks.length === 0) ||
      // ASUITE11-2561: avoid click on imported from change gear or bambooHr (with holiday exception)
      (infoDay.categoryId === 3 &&
        infoDay.changeGearTicketName &&
        entries.length === 0)
    ) {
      return "";
    }

    let newObjectToCheck = {
      hoursWorked: infoDay.totalHours,
      actualDate: day,
      dayNumber: new Date(day).getUTCDate(),
      timeEntries: entries,
      isHoliday: checkIsHoliday,
      isTimeOff: isEntryTimeOff(infoDay) && !isUnpaidTimeOffRow,
      isMealBreakOrNursingMother: checkMealBreakOrNursingMother(
        infoDay.dailyDetails
      ),
    };

    // new param in isSubtaskClickable, to avoid check for unpaid time off project for admin equals to actual user
    return isSubtaskClickable(
      newObjectToCheck,
      !isUnpaidTimeOffRow,
      null,
      null
    );
  };

  // Subtask will never contains paid time off (will be in main row) so won't check for holidays from bamboo
  const isSubtaskClickable = (
    day: DayInfo,
    avoidCheck: boolean,
    taskStatus: string | null,
    parentStatus: string | null
  ) => {
    // if time off => exceptions: meal break and nursing mother
    if (
      (day.isTimeOff &&
        (avoidCheck ? false : activeUserId === actualUser.value) &&
        !day.isMealBreakOrNursingMother) ||
      // ASUITE11-2512: not add or edit time entries if are closed/resolved/inactive/complete/canceled except super admin
      (taskStatus && isClosed(taskStatus) && day.hoursWorked === 0) ||
      (parentStatus && isClosed(parentStatus) && day.hoursWorked === 0)
    ) {
      return "";
    }
    const actualdate = new Date(
      getDateFromISOFormat(day.actualDate) + "T03:00:00Z"
    );

    let lastWorkableDay: any = getNextWeekDay(6, today);

    if (isTimeAdmin && activeUserId !== actualUser.value) {
      return getCellClassTodayClickable(day.actualDate);
    } else if (isTimeAdmin) {
      return getCellClassTodayClickable(day.actualDate);
      // exception ASUITE11-2561: normal users allow to see time entries imprted from bamboo disabled
    } else if (
      !isTimeAdmin &&
      day.timeEntries &&
      day?.timeEntries.length > 0 &&
      day.timeEntries.some((e) => e.source === BAMBOO_HR)
    ) {
      return getCellClassTodayClickable(day.actualDate);
    } else if (actualdate < lastWorkableDay) {
      // timeEntries is null => add, timeEntries is empty array, case when no time entries and subtask are same as last week, timeEntries has info, need to check if some can edit
      if (
        !day.timeEntries ||
        (day.timeEntries && day.timeEntries.length === 0) ||
        (day.timeEntries &&
          day.timeEntries.length > 0 &&
          day.timeEntries.some((e) => e.canEdit)) ||
        (day.timeEntries &&
          day.timeEntries.length > 0 &&
          day.timeEntries.some((e) => e.timeEntryStatusId === 1)) // at least one time entry have status "pending"
      ) {
        return getCellClassTodayClickable(day.actualDate);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const checkCellIfTimeOff = (
    row: RowSheetView,
    day: string,
    hasTasks: boolean
  ) => {
    if (hasTasks) {
      return false;
    } else {
      return (
        row.dailyDetails[day].hoursWorked > 0 &&
        row.dailyDetails[day].timeEntries.length > 0 &&
        isEntryTimeOff(row)
      );
    }
  };

  const checkBackgroundColor = (
    day: string,
    index: number,
    row: RowSheetView
  ) => {
    return isHoliday(new Date(day), holidays) ||
      checkCellIfTimeOff(
        row,
        day,
        row.tasks.length > 0 && isObjectEmpty(row.dailyDetails)
      ) ||
      arrayCompleteDayTimeOff.includes(getDateFromISOFormat(day))
      ? "table-holiday-td"
      : isToday(new Date(day))
      ? "table-today-td"
      : index === 0 || index === 6
      ? "table-td"
      : "table-td-white";
  };

  const checkBackgroundColorSubtask = (day: DayInfo, index: number) => {
    return day.isHoliday ||
      (day.isTimeOff && day.timeEntries) ||
      arrayCompleteDayTimeOff.includes(getDateFromISOFormat(day.actualDate))
      ? "table-holiday-td"
      : isToday(new Date(day.actualDate))
      ? "table-today-td"
      : index === 0 || index === 6
      ? "table-td"
      : "table-td-white";
  };

  const updateUser = (user: any) => {
    resetPage();
    setActualUser(user);
  };

  const resetPage = () => {
    setShowCardError(false);
    setSaveButtonDisabled(true);
    dispatch(setSubmitted(false));
    dispatch(resetEntries({ hasContent: hasContent(), pageDate: pageDate }));
  };

  // Unpaid time off could have a row with info in daily details and also have task
  const completeCellsWithoutLogic = (row: RowSheetView) => {
    let rowWithoutClick: any = undefined;
    if (isObjectEmpty(row.dailyDetails)) {
      rowWithoutClick = Object.keys(row.tasks[0].dailyDetails).map(
        (day, index) => (
          <td
            key={day}
            className={`border ${checkBackgroundColor(day, index, row)}`}
          ></td>
        )
      );
    } else {
      rowWithoutClick = Object.keys(row.dailyDetails).map(
        (day: any, index: number) => {
          totalSheetDaysHours[index] +=
            row.entityId === PROJ_UNPAID_TIME_OFF_ID
              ? calculateHoursFromTimeEntriesOnUnpaidTimeOff(
                  row.dailyDetails[day]
                )
              : row.dailyDetails[day].hoursWorked;
          return (
            <td
              key={day}
              id={day}
              className={`${checkBackgroundColor(day, index, row)} ${
                row.dailyDetails[day].hoursWorked % 0.25 ? "text-error" : ""
              } py-2 fw-500 text-center vert-middle border ${isTimeEntryClickable(
                day,
                row.dailyDetails[day].timeEntries,
                row,
                true
              )}`}
              onClick={() =>
                isTimeEntryClickable(
                  day,
                  row.dailyDetails[day].timeEntries,
                  row,
                  true
                )
                  ? editEntryWithoutTaskSheetView(
                      new Date(day).getUTCDate(),
                      row,
                      row.dailyDetails[day].timeEntries
                    )
                  : undefined
              }
            >
              {row.dailyDetails[day].hoursWorked > 0
                ? quantityFormat(row.dailyDetails[day].hoursWorked)
                : "-"}
            </td>
          );
        }
      );
    }
    return rowWithoutClick;
  };

  const calculateHoursFromTimeEntriesOnUnpaidTimeOff = (info: any) => {
    let total: number = 0;
    if (info.hoursWorked > 0 && info.timeEntries.length > 0) {
      info.timeEntries.forEach((elem) => {
        if (
          elem.entityId === PROJ_UNPAID_TIME_OFF_ID &&
          elem.taskTypeId !== TASKTYPE_MEAL_BREAK_ID
        ) {
          total += elem.hours;
        }
      });
    }
    return total;
  };

  const getSumofTotalsrow = () => {
    return totalSheetDaysHours.reduce((a, b) => a + b, 0);
  };

  const enabledArrowAddOneDay = () => {
    return (
      isSuperTimeAdmin ||
      (isSupervisorOrPO && activeUserId !== actualUser.value) ||
      getDateFromISOFormat(pageDate.toJSON()) <
        getDateFromISOFormat(lastWorkableDayForArrow.toJSON())
    );
  };

  const [addMealBreakFromListView, setAddMealBreakFromListView] =
    useState(false);

  const addMealBreak = (date: string) => {
    setEntryInitalInfo({
      entityId: PROJ_MEAL_BREAK_ID,
      modalDate: date,
    });
    setActualTimeEntries([]);
    setAddMealBreakFromListView(true);
    showModal();
  };

  return (
    <>
      <ModalPortal ref={modalMoreInfoRef}>
        <ModalMoreInfoTimeEntry
          btnCancel={closeMoreInfoModal}
          type="moreInfoModal"
          actions={entryActions}
        />
      </ModalPortal>
      <ModalPortal ref={modalDeleteRef}>
        <ModalDeleteTimeEntry
          btnCancel={closeDeleteModal}
          btnAction={deleteEntryModal}
        />
      </ModalPortal>
      <ModalPortal ref={modalRef}>
        <ModalTimeEntry
          title="Modal Entry"
          projectList={selectProjectOrWorkOrder}
          btnCancel={closeModal}
          reloadPage={refreshPage}
          resetPage={resetPage}
          type="formModal"
          editMode={
            (isListWiew && !addMealBreakFromListView) ||
            actualTimeEntries.length > 0
          }
          entry={
            (isListWiew && !addMealBreakFromListView) ||
            actualTimeEntries.length > 0
              ? activeEntry
              : null
          }
          userIdParam={actualUser ? actualUser.value : activeUserId}
          holidays={holidays}
          entryInitalInfo={entryInitalInfo}
          actualTimeEntries={isListView ? null : actualTimeEntries}
          deleteEntry={deleteEntry}
          timeEntriesList={timeEntriesList}
          isQueryPage={false}
          isListWiew={isListWiew}
          addMealBreakFromListView={addMealBreakFromListView}
        />
      </ModalPortal>
      <div className="content">
        <div className="content-header w-100" style={{ margin: "0 0 20px" }}>
          <h2>Enter Time</h2>
        </div>
        <div className="d-flex mb-4">
          {isTimeAdmin && (
            <Select
              className="times-header-input me-3"
              options={usersAssignees}
              value={actualUser}
              onChange={(user) => updateUser(user)}
              isDisabled={
                isLoading || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
              }
            />
          )}
          <Calendar
            className="times-header-input pointer"
            date={pageDate}
            onChange={(date: Date) => updateDateByCalendar(new Date(date))}
            isDisabled={isLoading}
            maxDate={
              !isTimeAdmin ||
              (isSupervisorOrPO &&
                activeUserId === actualUser.value &&
                !isSuperTimeAdmin)
                ? lastWorkableDay
                : undefined
            }
          />
          <BiChevronLeft
            className="arrow-icon pointer ms-3"
            onClick={() => (!isLoading ? removeOneDay() : null)}
          />
          <BiChevronRight
            className={`me-3 ${
              !isLoading && enabledArrowAddOneDay()
                ? "arrow-icon pointer"
                : "arrow-icon-nohover today-disabled"
            }`}
            onClick={() =>
              !isLoading && enabledArrowAddOneDay() ? addOneDay() : null
            }
          />
          <div
            className={`link-text pointer ${
              isToday(pageDate) ? "today-disabled" : ""
            }`}
            style={{ alignSelf: "center" }}
            onClick={() =>
              !isLoading || isToday(pageDate) ? setToday() : null
            }
          >
            {TODAY}
          </div>
        </div>

        <hr className="header-separator" />

        <SummaryTotals
          showTitle={true}
          showedWeek={showedWeek}
          totals={summary.totalHours}
          billable={summary.billableHours}
          financed={summary.financedHours}
          nonBillable={summary.nonBillableHours}
          internal={summary.internalHours}
          timeOff={summary.timeOffHours}
          isLoading={isLoading}
          scheduledHours={scheduledHours}
          avoidCheckMissingHours={false}
          pageDate={pageDate}
        />

        <div className="d-flex mt-4 justify-content-between">
          <div className="view-buttons">
            <button
              className={`btn view-button-secondary ${
                isListWiew ? "active" : ""
              }`}
              onClick={() => (!isLoading ? changeView(true) : null)}
            >
              <BiListUl className="view-icon me-1" />
              <span className="align-middle">List</span>
            </button>
            <button
              className={`btn view-button-secondary ${
                !isListWiew ? "active" : ""
              }`}
              style={{ marginLeft: "-5px" }}
              onClick={() => (!isLoading ? changeView(false) : null)}
            >
              <BiGridAlt className="view-icon me-1" />
              <span className="align-middle">Sheet</span>
            </button>
          </div>
          {isListWiew && (
            <div className="view-buttons">
              {isDateValidToEnterTime(
                isSuperTimeAdmin,
                isSupervisorOrPO,
                activeUserId,
                actualUser.value,
                pageDate,
                previousWorkableDay,
                lastWorkableDay
              ) && (
                <button
                  className="btn button-secondary"
                  onClick={() => addNewEntry()}
                  disabled={isLoading}
                >
                  Add new entries
                </button>
              )}
              {isDateValidToEnterTime(
                isSuperTimeAdmin,
                isSupervisorOrPO,
                activeUserId,
                actualUser.value,
                pageDate,
                previousWorkableDay,
                lastWorkableDay
              ) && (
                <button
                  className="btn btn-primary ms-3"
                  onClick={() => submitEntries()}
                  disabled={saveButtonDisabled || isLoading}
                >
                  Save all entries
                </button>
              )}
            </div>
          )}
          {!isListWiew &&
            isDateValidToEnterTime(
              isSuperTimeAdmin,
              isSupervisorOrPO,
              activeUserId,
              actualUser.value,
              pageDate,
              previousWorkableDay,
              lastWorkableDay
            ) && (
              <div className="view-buttons">
                <button
                  className="btn button-secondary"
                  onClick={() => addNewEntrySheetView()}
                >
                  Add new entry
                </button>
              </div>
            )}
        </div>

        {!isLoading && isListView && showCardError && (
          <div className="card-error mt-3">
            <div className="error-message mt-3">
              <BiError className="error-icon big ms-3" />
              <span className="red-text ms-2">Oops! Something’s missing.</span>
              <span className="black-text ms-2">
                The fields below need your attention.
              </span>
            </div>
          </div>
        )}

        {!isLoading && isListView && missingTime.missingTime && (
          <div className="card-error mt-3">
            <div className="error-message mt-3">
              <BiError className="error-icon big ms-3" />
              <span className="red-text ms-2">Missing time.</span>
              <span className="black-text ms-2">
                You are missing {missingTime.hours} hours between{" "}
                {dateFormatEntryEdit(missingTime.startDate)} and{" "}
                {dateFormatEntryEdit(missingTime.endDate)}. Contact your PM or
                Supervisor to get them entered.
              </span>
            </div>
          </div>
        )}

        {!isLoading && isListView && beforeYesterdayMealBreakReminders && (
          <div className="card-error mt-3">
            <div className="error-message mt-3">
              <BiError className="error-icon big ms-3" />
              <span className="red-text ms-2">Meal Break.</span>
              <span className="black-text ms-2">
                You are required to enter a meal break on several dates in the
                past because you worked 6.00 or more hours on those dates.
                Contact your Supervisor or Global Talent to get it entered.
              </span>
            </div>
          </div>
        )}

        {false && !isLoading && isListView && yesterdayMealBreakReminders && (
          <div className="card-warning mt-3">
            <div className="warning-message mt-3">
              <BiErrorCircle className="warning-icon big ms-3" />
              <span className="orange-text ms-2">Heads up.</span>
              <span className="black-text ms-2">
                You are required to enter a meal break on{" "}
                {dateFormatEntryEdit(yesterday)} because you worked 6.00 or more
                hours
              </span>
              <span
                className="td-task-link ms-2"
                onClick={() => addMealBreak(yesterday)}
              >
                Add a meal break
              </span>
            </div>
          </div>
        )}

        {false && !isLoading && isListView && todayMealBreakReminders && (
          <div className="card-warning mt-3">
            <div className="warning-message mt-3">
              <BiErrorCircle className="warning-icon big ms-3" />
              <span className="orange-text ms-2">Heads up.</span>
              <span className="black-text ms-2">
                You are required to enter a meal break on{" "}
                {dateFormatEntryEdit(today.toISOString())} because you worked
                6.00 or more hours
              </span>
              <span
                className="td-task-link ms-2"
                onClick={() => addMealBreak(today.toISOString())}
              >
                Add a meal break
              </span>
            </div>
          </div>
        )}

        {isListWiew &&
          !isLoading &&
          isDateValidToEnterTime(
            isSuperTimeAdmin,
            isSupervisorOrPO,
            activeUserId,
            actualUser.value,
            pageDate,
            previousWorkableDay,
            lastWorkableDay
          ) &&
          entryList.map((elem: TimeEntryForm) => {
            return (
              <EntryHoursBox
                key={elem.entryId}
                state={elem}
                projectsWorkOrders={selectProjectOrWorkOrder}
                isLoadingProjects={isLoading}
                isTouched={checkEntriesStatus}
                editMode={false}
                isModal={false}
                holidays={holidays}
                timeEntriesList={timeEntriesList}
                onClear={checkEntriesValidations}
                isQueryPage={false}
                userIdParam={actualUser ? actualUser.value : activeUserId}
              />
            );
          })}

        {isListWiew &&
          !isLoading &&
          timeEntriesList.filter((entry) =>
            isSameDay(entry.entryDate, pageDate)
          ).length > 0 && (
            <>
              <div className="d-flex mt-4 justify-content-between sticky-date sticky">
                <div className="left-side d-flex align-items-center">
                  <BiCalendar style={{ width: "20px", height: "20px" }} />
                  <div className="table-date ms-2">
                    {nameDayDateFormat(pageDate.toISOString())}
                  </div>
                </div>
                <DayTotals timeEntries={timeEntriesList} actualDay={pageDate} />
              </div>
              <table
                className="table white-table-header-background mt-3 mb-5"
                style={{ width: "100%" }}
              >
                <thead className="align-middle sticky-header-with-date sticky">
                  <tr>
                    <th className="border-dark border-top fw-500 py-0 ps-4 column-25">
                      Client / Project / Task
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-4 column-15">
                      Hours / Task Type
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-4 column-30">
                      Description
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-4 column-15">
                      Billable/Price
                    </th>
                    <th className="border-dark border-top fw-500 py-0 column-5">
                      Status
                    </th>
                    <th className="border-dark border-top fw-500 py-0 ps-3 column-15">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {timeEntriesList
                    .filter((entry) => isSameDay(entry.entryDate, pageDate))
                    .map((entry: EntryListView) => {
                      return (
                        <tr key={newUuid()} style={{ verticalAlign: "middle" }}>
                          <td className="table-td ps-4 py-3 fw-500">
                            {entry.categoryId !== 3 && (
                              <>
                                <div className="td-small-text">
                                  {entry.clientName}
                                </div>
                                <a
                                  className="td-task-link"
                                  href={navigateTo(entry)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Project{" "}
                                  {entry.projectId
                                    ? entry.projectId
                                    : entry.entityId}{" "}
                                  - {entry.entityDescription}
                                </a>
                              </>
                            )}
                            {entry.categoryId === 3 && (
                              <>
                                <div className="td-small-text">
                                  {entry.clientName}
                                </div>
                                <a
                                  className="td-task-link"
                                  href={navigateTo(entry)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Work Order {entry.entityId} -{" "}
                                  {entry.entityDescription}
                                </a>
                              </>
                            )}
                            {isTimeAdmin &&
                              activeUserId !== actualUser.value && (
                                <div className="td-small-text mt-1">
                                  {actualUser.label}
                                </div>
                              )}
                          </td>
                          <td className="table-td ps-4 py-3 fw-500">
                            <div
                              className={`td-bold ${
                                entry.hours % 0.25 ? "text-error" : ""
                              }`}
                            >
                              {quantityFormat(entry.hours)}
                            </div>
                            <div className="td-text">{entry.taskType}</div>
                          </td>
                          <td className="table-td ps-4 py-3 fw-500">
                            <div className="td-text text-wrap">
                              {entry.description}
                            </div>
                          </td>
                          <td className="table-td ps-4 py-3 fw-500">
                            <div className="td-bold">
                              {entry.timeEntryType === BILLABLE
                                ? "Yes"
                                : entry.timeEntryType === UNPAID
                                ? "Unpaid"
                                : "No"}
                              {entry.timeEntryTypeId === 3 ? "-Financed" : ""}
                            </div>
                            {entry.timeEntryType === BILLABLE && (
                              <div className="td-small-text">
                                {entry.userPriceLevel}
                              </div>
                            )}
                            {nonBillableIds.includes(
                              entry.timeEntryTypeId.toString().toLowerCase()
                            ) && (
                              <div className="td-small-text">
                                {entry.nonBillableReason}
                              </div>
                            )}
                            {isTimeAdmin && (
                              <div className="td-text">
                                ${quantityFormat(entry.price)}
                              </div>
                            )}
                          </td>
                          <td className="table-td py-4 fw-500">
                            <div
                              className={`td-status ${entry.status} ${
                                entry.status === LOCKED ? "d-flex" : ""
                              }`}
                            >
                              {entry.status}
                              {entry.status === LOCKED && (
                                <BiLockAlt
                                  className="ms-1"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              )}
                            </div>
                          </td>
                          <td className="table-td action-icons-td py-4">
                            <div className="d-flex flex-column">
                              {(entry.timeEntrySourceId === 3 ||
                                (entry.timeEntrySourceId === 2 &&
                                  !entry.sourceUrl)) && (
                                <div className="entry-text-imported">
                                  From {entry.source}
                                </div>
                              )}
                              {entry.timeEntrySourceId === 2 &&
                                entry.sourceUrl && (
                                  <Link
                                    to={entry.sourceUrl}
                                    className="entry-text-imported text-decoration-none"
                                  >
                                    From {entry.source}
                                  </Link>
                                )}
                              <div>
                                <Tooltip
                                  title={<b>{"More Info"}</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiInfoCircle
                                      className={`icon-action ${
                                        !isLoading ? "" : "disabled"
                                      }`}
                                      onClick={() => showMoreInfo(entry)}
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={<b>{"Edit"}</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiEditAlt
                                      className={`icon-action ${
                                        !isLoading &&
                                        iconEditEnabled(
                                          entry,
                                          activeUserId,
                                          actualUser.value,
                                          isSuperTimeAdmin,
                                          isSupervisorOrPO,
                                          previousWorkableDay,
                                          today,
                                          lastWorkableDay
                                        )
                                          ? ""
                                          : "disabled"
                                      }`}
                                      onClick={() =>
                                        !isLoading &&
                                        iconEditEnabled(
                                          entry,
                                          activeUserId,
                                          actualUser.value,
                                          isSuperTimeAdmin,
                                          isSupervisorOrPO,
                                          previousWorkableDay,
                                          today,
                                          lastWorkableDay
                                        )
                                          ? editEntry(entry)
                                          : undefined
                                      }
                                    />
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={<b>{"Delete"}</b>}
                                  placement="top"
                                  TransitionComponent={Zoom}
                                  arrow
                                >
                                  <span>
                                    <BiTrash
                                      className={`icon-action ${
                                        !isLoading &&
                                        iconDeleteEnabled(
                                          entry,
                                          activeUserId,
                                          actualUser.value,
                                          isSuperTimeAdmin,
                                          isSupervisorOrPO,
                                          previousWorkableDay,
                                          today,
                                          lastWorkableDay
                                        )
                                          ? ""
                                          : "disabled"
                                      }`}
                                      onClick={() =>
                                        !isLoading &&
                                        iconDeleteEnabled(
                                          entry,
                                          activeUserId,
                                          actualUser.value,
                                          isSuperTimeAdmin,
                                          isSupervisorOrPO,
                                          previousWorkableDay,
                                          today,
                                          lastWorkableDay
                                        )
                                          ? deleteEntry(entry)
                                          : undefined
                                      }
                                    />
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}

        {!isLoading && !isListView && timeEntriesSheetView && (
          <table
            className={`table-sheet-view mt-3 ${
              timeEntriesSheetView.length > 0 ? "mb-5" : ""
            }`}
            style={{ width: "100%", color: "#2f2d31" }}
          >
            <thead className="align-middle sticky-header sticky">
              <tr className="sheet-view-border no-sides">
                <th
                  className="table-td border-dark border-top fw-500 py-2 px-3 column-36 sheet-view-border no-sides"
                  id="Client/Project"
                >
                  Client / Project / Task
                </th>
                <th
                  className="table-totals fw-500 py-2 column-8 text-center totals-border"
                  id="Totals"
                >
                  Totals
                </th>
                {actualWeek &&
                  actualWeek.map((day: CustomWeekDay, index: number) => {
                    return (
                      <th
                        key={day.numDay}
                        className={`sheet-view-border no-sides ${
                          arrayCompleteDayTimeOff.includes(
                            getDateFromISOFormat(day.fullDate.toISOString())
                          )
                            ? "table-holiday-td"
                            : day.isToday
                            ? "table-today-th"
                            : "table-td"
                        } border-dark border-top fw-500 py-2 column-8`}
                      >
                        <div className="d-flex flex-column text-center">
                          <div>{day.day}</div>
                          <div>{day.numDay}</div>
                        </div>
                      </th>
                    );
                  })}
              </tr>
            </thead>

            {timeEntriesSheetView.length > 0 && (
              <tbody>
                <>
                  {timeEntriesSheetView.map((row: RowSheetView) => {
                    if (row.hasTasks) {
                      let subtasks: Subtask[] = [];
                      if (row.tasks.length > 0) {
                        row.tasks.forEach((subtask: TaskSheetView) => {
                          subtasks.push({
                            task: subtask.taskName,
                            taskId: subtask.taskId,
                            taskStatus: subtask.taskStatus,
                            parentStatus: row.entityStatus,
                            days: [],
                          });
                        });
                      }
                      return (
                        <>
                          <tr key={row.entityId}>
                            <td
                              className="table-td-white ps-3 fw-500 no-left project-task"
                              id="clientName"
                            >
                              <div className="d-flex flex-column">
                                <div className="td-small-text">
                                  {row.clientName}
                                </div>
                                {row.categoryId !== 3 && (
                                  <div className="d-flex">
                                    <img
                                      src={projectIcon}
                                      className="me-1"
                                      alt="project-icon"
                                    />
                                    <a
                                      className="td-task-link"
                                      href={navigateToFromSheetView(row)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Project{" "}
                                      {row.projectId
                                        ? row.projectId
                                        : row.entityId}
                                    </a>
                                    <Tooltip
                                      title={<b>{row.entityName}</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <div className="td-name-text">
                                        - {row.entityName}
                                      </div>
                                    </Tooltip>
                                    {isClosed(row.entityStatus) && (
                                      <div
                                        className={`mx-2 td-status ${row.entityStatus}`}
                                      >
                                        {row.entityStatus === RESOLVED_TEXT
                                          ? RESOLVED_TEXT
                                          : CLOSED_TEXT}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {row.categoryId === 3 && (
                                  <div className="d-flex">
                                    <img
                                      src={workorderIcon}
                                      className="me-1"
                                      alt="workorder-icon"
                                    />
                                    <a
                                      className="td-task-link"
                                      href={navigateToFromSheetView(row)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Work Order {row.entityId}
                                    </a>
                                    <Tooltip
                                      title={<b>{row.entityName}</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <div className="td-name-text">
                                        - {row.entityName}
                                      </div>
                                    </Tooltip>
                                    {isClosed(row.entityStatus) && (
                                      <div
                                        className={`mx-2 td-status ${row.entityStatus}`}
                                      >
                                        {row.entityStatus === RESOLVED_TEXT
                                          ? RESOLVED_TEXT
                                          : CLOSED_TEXT}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td
                              className="table-totals py-2 fw-500 text-center vert-middle totals-border total-hours-day"
                              id="totalHours"
                            >
                              {quantityFormat(row.totalHours)}
                            </td>
                            <>{completeCellsWithoutLogic(row)}</>
                            <>
                              {row.tasks.forEach((task: TaskSheetView) => {
                                Object.keys(task.dailyDetails).map(
                                  (day: string, index: number) => {
                                    let taskSelected,
                                      allTaskSelected: any[] = [];
                                    if (
                                      task.dailyDetails[day].timeEntries
                                        .length > 0
                                    ) {
                                      // check if a unpaid time off time entries is equal to eight hours
                                      if (
                                        isEntryTimeOff(row) &&
                                        task.dailyDetails[day].hoursWorked ===
                                          scheduledHours
                                      ) {
                                        arrayCompleteDayTimeOff.push(
                                          getDateFromISOFormat(day)
                                        );
                                      }
                                      // for to check more than one task type in a day
                                      if (
                                        task.dailyDetails[day].hoursWorked > 0
                                      ) {
                                        // Sum totals by day
                                        totalSheetDaysHours[index] +=
                                          task.taskName !== "Meal Break"
                                            ? task.dailyDetails[day].hoursWorked
                                            : 0;
                                        // Find task by name
                                        taskSelected = subtasks.find(
                                          (x) => x.task === task.taskName
                                        );
                                        // avois issue on sheet view empty with time entries from past week
                                        if (taskSelected) {
                                          allTaskSelected.push(
                                            taskSelected.task
                                          );
                                          taskSelected.days.push({
                                            hoursWorked:
                                              task.dailyDetails[day]
                                                .hoursWorked,
                                            actualDate: day,
                                            dayNumber: new Date(
                                              day
                                            ).getUTCDate(),
                                            timeEntries:
                                              task.dailyDetails[day]
                                                .timeEntries,
                                            isHoliday: isHoliday(
                                              new Date(day),
                                              holidays
                                            ),
                                            isTimeOff: isEntryTimeOff(row),
                                            isMealBreakOrNursingMother:
                                              checkMealBreakOrNursingMother(
                                                task.dailyDetails
                                              ),
                                          });
                                        }
                                      }
                                    } else {
                                      // Find task by name
                                      taskSelected = subtasks.find(
                                        (x) => x.task === task.taskName
                                      );
                                      taskSelected.days.push({
                                        hoursWorked: 0,
                                        actualDate: day,
                                        dayNumber: new Date(day).getUTCDate(),
                                        timeEntries: null,
                                        isHoliday: isHoliday(
                                          new Date(day),
                                          holidays
                                        ),
                                        isTimeOff: isEntryTimeOff(row),
                                        isMealBreakOrNursingMother:
                                          checkMealBreakOrNursingMother(
                                            task.dailyDetails
                                          ),
                                      });
                                    }
                                    return null;
                                  }
                                );
                              })}
                            </>
                          </tr>
                          <>
                            {subtasks.length > 0 &&
                              subtasks.map((elem: Subtask) => {
                                return (
                                  <tr key={elem.task + elem.days[0].dayNumber}>
                                    <td className="table-td-white fw-500 sheet-view-border no-left no-right sheet-view-subtask">
                                      <div className="d-flex">
                                        <Tooltip
                                          title={<b>{elem.task}</b>}
                                          placement="bottom"
                                          TransitionComponent={Zoom}
                                          arrow
                                        >
                                          <div className="td-text">
                                            {elem.task}
                                          </div>
                                        </Tooltip>
                                        {isClosed(elem.taskStatus) && (
                                          <div
                                            className={`m-2 td-status ${elem.taskStatus}`}
                                            style={{ paddingTop: "5px" }}
                                          >
                                            {elem.taskStatus === RESOLVED_TEXT
                                              ? RESOLVED_TEXT
                                              : CLOSED_TEXT}
                                          </div>
                                        )}
                                      </div>
                                    </td>
                                    <td className="table-totals py-2 fw-500 text-center vert-middle totals-border total-hours-subtask">
                                      {sumHoursWorked(elem.days)}
                                    </td>
                                    <>
                                      {elem.days.map((day, index) => {
                                        return (
                                          <td
                                            key={day.dayNumber}
                                            className={`${checkBackgroundColorSubtask(
                                              day,
                                              index
                                            )} py-2 fw-500 text-center vert-middle border ${
                                              day.hoursWorked % 0.25
                                                ? "text-error"
                                                : ""
                                            } ${isSubtaskClickable(
                                              day,
                                              false,
                                              elem.taskStatus,
                                              elem.parentStatus
                                            )}`}
                                            onClick={() =>
                                              isSubtaskClickable(
                                                day,
                                                false,
                                                elem.taskStatus,
                                                elem.parentStatus
                                              )
                                                ? editEntrySheetView(
                                                    day,
                                                    elem.taskId,
                                                    row
                                                  )
                                                : undefined
                                            }
                                          >
                                            {day.hoursWorked
                                              ? quantityFormat(day.hoursWorked)
                                              : "-"}
                                          </td>
                                        );
                                      })}
                                    </>
                                  </tr>
                                );
                              })}
                          </>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <tr
                            key={row.entityId}
                            className="sheet-view-border no-sides"
                          >
                            <td
                              className="table-td-white ps-3 fw-500 no-left project-task"
                              id="clientName"
                            >
                              <div className="d-flex flex-column">
                                <div className="td-small-text">
                                  {row.clientName}
                                </div>
                                {row.categoryId !== 3 && (
                                  <div className="d-flex">
                                    <img
                                      src={projectIcon}
                                      className="me-1"
                                      alt="project-icon"
                                    />
                                    <a
                                      className="td-task-link"
                                      href={navigateToFromSheetView(row)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Project{" "}
                                      {row.projectId
                                        ? row.projectId
                                        : row.entityId}
                                    </a>
                                    <Tooltip
                                      title={<b>{row.entityName}</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <div className="td-name-text">
                                        - {row.entityName}
                                      </div>
                                    </Tooltip>
                                    {isClosed(row.entityStatus) && (
                                      <div
                                        className={`mx-2 td-status ${row.entityStatus}`}
                                      >
                                        {row.entityStatus === RESOLVED_TEXT
                                          ? RESOLVED_TEXT
                                          : CLOSED_TEXT}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {row.categoryId === 3 && (
                                  <div className="d-flex">
                                    <img
                                      src={workorderIcon}
                                      className="me-1"
                                      alt="workorder-icon"
                                    />
                                    <a
                                      className="td-task-link"
                                      href={navigateToFromSheetView(row)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Work Order {row.entityId}
                                    </a>
                                    <Tooltip
                                      title={<b>{row.entityName}</b>}
                                      placement="top"
                                      TransitionComponent={Zoom}
                                      arrow
                                    >
                                      <div className="td-name-text">
                                        - {row.entityName}
                                      </div>
                                    </Tooltip>
                                    {isClosed(row.entityStatus) && (
                                      <div
                                        className={`mx-2 td-status ${row.entityStatus}`}
                                      >
                                        {row.entityStatus === RESOLVED_TEXT
                                          ? RESOLVED_TEXT
                                          : CLOSED_TEXT}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td
                              className="table-totals py-2 fw-500 text-center vert-middle totals-border total-hours-day"
                              id="totalHours"
                            >
                              {quantityFormat(row.totalHours)}
                            </td>
                            <>
                              {Object.keys(row.dailyDetails).map(
                                (day: any, index: number) => {
                                  totalSheetDaysHours[index] +=
                                    row.entityId === PROJ_UNPAID_TIME_OFF_ID
                                      ? calculateHoursFromTimeEntriesOnUnpaidTimeOff(
                                          row.dailyDetails[day]
                                        )
                                      : row.dailyDetails[day].hoursWorked;
                                  return (
                                    <td
                                      key={day}
                                      id={day}
                                      className={`${checkBackgroundColor(
                                        day,
                                        index,
                                        row
                                      )} py-2 fw-500 text-center vert-middle border ${isTimeEntryClickable(
                                        day,
                                        row.dailyDetails[day].timeEntries,
                                        row,
                                        false
                                      )} ${
                                        row.dailyDetails[day].hoursWorked % 0.25
                                          ? "text-error"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        isTimeEntryClickable(
                                          day,
                                          row.dailyDetails[day].timeEntries,
                                          row,
                                          false
                                        )
                                          ? editEntryWithoutTaskSheetView(
                                              new Date(day).getUTCDate(),
                                              row,
                                              row.dailyDetails[day].timeEntries
                                            )
                                          : undefined
                                      }
                                    >
                                      {row.dailyDetails[day].hoursWorked > 0
                                        ? quantityFormat(
                                            row.dailyDetails[day].hoursWorked
                                          )
                                        : "-"}
                                    </td>
                                  );
                                }
                              )}
                            </>
                          </tr>
                        </>
                      );
                    }
                  })}

                  {/* totals row */}
                  <>
                    {timeEntriesSheetView &&
                      timeEntriesSheetView.length > 0 && (
                        <tr>
                          <td className="table-td sheet-view-border no-sides"></td>
                          <td className="table-totals fw-700 py-2 text-center vert-middle totals-border">
                            {quantityFormat(getSumofTotalsrow())}
                          </td>
                          <>
                            {actualWeek &&
                              actualWeek.map(
                                (day: CustomWeekDay, index: number) => {
                                  return (
                                    <th
                                      key={day.day + day.numDay}
                                      className={`sheet-view-border no-sides ${
                                        arrayCompleteDayTimeOff.includes(
                                          getDateFromISOFormat(
                                            day.fullDate.toISOString()
                                          )
                                        )
                                          ? "table-holiday-td"
                                          : day.isToday
                                          ? "table-today-th"
                                          : "table-td"
                                      } py-2 column-8 sum-totals-hours`}
                                    >
                                      <div
                                        className={`d-flex flex-column text-center ${
                                          totalSheetDaysHours[index] <
                                            scheduledHours &&
                                          index !== 0 &&
                                          index !== 6 &&
                                          !day.isToday &&
                                          !isDateAfterToday(day.fullDate)
                                            ? "text-error"
                                            : ""
                                        }`}
                                      >
                                        {totalSheetDaysHours[index] === 0
                                          ? !isDateAfterToday(day.fullDate)
                                            ? quantityFormat(0)
                                            : ""
                                          : quantityFormat(
                                              totalSheetDaysHours[index]
                                            )}
                                      </div>
                                    </th>
                                  );
                                }
                              )}
                          </>
                        </tr>
                      )}
                  </>
                </>
              </tbody>
            )}
          </table>
        )}
        {!isLoading &&
          !isListView &&
          timeEntriesSheetView &&
          timeEntriesSheetView.length === 0 && (
            <div className="py-5" style={{ backgroundColor: "#F9FAFB" }}>
              <NoContent
                title="Nothing here yet"
                text="Looks like you haven’t logged any time this week."
                image={image}
                style={{ margin: "0 auto" }}
              />
            </div>
          )}

        <Footer />
      </div>
    </>
  );
};

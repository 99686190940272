import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Footer from "../../SharedModule/components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  ROUTE_TIMESHEETS_REPORTS,
  ROUTE_WHO_IS_OUT_REPORT,
  TIME_ADMIN_ACCESS,
} from "../../SharedModule/utils/constants";
import { Link } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import { checkDataIsLoaded } from "../utils/timesUtils";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useWhoIsOutReport } from "../hooks/useWhoIsOutReport";
import { verifyLinkAccess } from "../../SharedModule/utils/accessUtils";
import { BiLeftArrowAlt, BiInfoCircle } from "react-icons/bi";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Select } from "../../SharedModule/components/Select";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Radio } from "../../SharedModule/components/Radio";
import { WhoIsOutUser } from "../types";
import { changeOrder, selectOrderCriteria } from "../redux/reducers/whoIsOutReport.reducer";
import { quantityFormat } from "../../SharedModule/utils/formatters";

export const WhoIsOutReportsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();

  const {
    MetaTimesInfo,
    whoIsOutUserList,
    filters,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isTimeAdmin,
    isLoadingUserInfo,
  } = useWhoIsOutReport();

  const sortBy = useSelector(selectOrderCriteria);

  let isLoading = false;

  const timeFrameOptios = [
    {
      value: 1,
      label: "Next 30 days",
    },
    {
      value: 2,
      label: "Next 60 days",
    },
    {
      value: 3,
      label: "Next 90 days",
    },
    {
      value: 4,
      label: "End of year",
    },
  ];

  const [reportFilters, setReportFilters] = useState({
    timeFrame: !filters.timeFrame ? timeFrameOptios[0] : filters.timeFrame,
    costCenter: null,
    project: null,
    groupByDate: true,
  });

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    dispatch(setActiveTab(ROUTE_WHO_IS_OUT_REPORT));
  }, [trackPageView, isTimeAdmin]);

  const submit = () => {
    console.log("submit");
  };

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && (
          <div className="content">
            <div className="mt-2 button-header">
              <Link
                className="back-button-container text-decoration-none"
                to={ROUTE_TIMESHEETS_REPORTS}
              >
                <BiLeftArrowAlt className="back-button-arrow" />
                <span className="back-button-text">Back to Time reports</span>
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <h2 className="flex-fill">Who is out in the future</h2>
            </div>

            <form className="card entry-hour-box-background p-4 mt-3 mb-5">
              <div className="d-flex mb-4 justify-content-between">
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Time Frame</label>
                  <Select
                    placeholder="All"
                    options={timeFrameOptios}
                    value={reportFilters.timeFrame}
                    onChange={() => console.log("changed")}
                    isDisabled={
                      isLoading ||
                      !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
                    }
                  />
                </div>
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">
                    Cost Center
                    <Tooltip
                      title={<b>List users that belong to this Cost Center</b>}
                      placement="right"
                      TransitionComponent={Zoom}
                      arrow
                    >
                      <span>
                        <BiInfoCircle className="disc-icon ms-1" />
                      </span>
                    </Tooltip>
                  </label>
                  <Select
                    isMulti
                    placeholder="All Cost Centers"
                    options={MetaTimesInfo.costCentersApprovalTime}
                    value={reportFilters.costCenter}
                    onChange={(costCenters) =>
                      setReportFilters({
                        ...reportFilters,
                        costCenter: costCenters,
                      })
                    }
                    isDisabled={
                      isLoading ||
                      !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
                    }
                  />
                </div>
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Projects</label>
                  <Select
                    isMulti
                    placeholder="All"
                    options={MetaTimesInfo.projectsApprovalTime}
                    value={reportFilters.project}
                    onChange={(projects) =>
                      setReportFilters({ ...reportFilters, project: projects })
                    }
                    isDisabled={
                      isLoading ||
                      !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
                    }
                  />
                </div>
                <div className="flex-column col-sm-2">
                  <label className="col-form-label">Group By</label>
                  <div>
                    <div className="w-50 d-inline-block">
                      <Radio
                        label="Date"
                        value={reportFilters.groupByDate}
                        checked={reportFilters.groupByDate}
                        onChange={() =>
                          setReportFilters({
                            ...reportFilters,
                            groupByDate: true,
                          })
                        }
                      />
                    </div>
                    <div className="w-50 d-inline-block">
                      <Radio
                        label="User"
                        value={!reportFilters.groupByDate}
                        checked={!reportFilters.groupByDate}
                        onChange={() =>
                          setReportFilters({
                            ...reportFilters,
                            groupByDate: false,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginTop: "35px" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => submit()}
                    disabled={true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>

            <hr className="header-separator" />

            <table
              className="grey-table-header-background time-confirmation table my-5"
              style={{ width: "100%" }}
            >
              <thead className="align-middle sticky-header sticky">
                <tr>
                  <th
                    className={`border-left border-right border-top fw-500 py-3 ps-4 column-20 ${
                      sortBy.orderBy === "userName"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    key="userName"
                  >
                    Person
                  </th>
                  <th
                    className={`border-top border-right fw-500 py-3 ps-4 column-20 ${
                      sortBy.orderBy === "costCenter"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    key="costCenter"
                    id="CostCenter"
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "costCenter",
                          dataType: "string",
                        })
                      )
                    }
                  >
                    Cost Center
                  </th>
                  <th
                    className={`border-top border-right fw-500 py-3 ps-4 column-15 ${
                      sortBy.orderBy === "date"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    key="date"
                    id="Date"
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "date",
                          dataType: "date",
                        })
                      )
                    }
                  >
                    Date
                  </th>
                  <th
                    className={`border-top border-right fw-500 py-3 ps-4 column-15 ${
                      sortBy.orderBy === "hours"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    key="hours"
                    id="Hours"
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "hours",
                          dataType: "number",
                        })
                      )
                    }
                  >
                    Hours
                  </th>
                  <th
                    className={`border-right border-top fw-500 py-3 ps-4 column-20 ${
                      sortBy.orderBy === "description"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "description",
                          dataType: "string",
                        })
                      )
                    }
                    id="Description"
                    key="description"
                  >
                    Difference
                  </th>
                </tr>
              </thead>
              <tbody>
                {whoIsOutUserList &&
                  whoIsOutUserList.map((user: WhoIsOutUser, index: number) => {
                    return (
                      <tr
                        key={user.userName}
                        style={{ verticalAlign: "middle" }}
                        className={index % 2 === 0 ? "even-row" : "odd-row"}
                      >
                        <td className="py-3 ps-4 fw-500">
                          <div className="td-text">{user.userName}</div>
                        </td>
                        <td className="py-3 ps-4 fw-500">
                          <div className="td-text">{user.costCenter}</div>
                        </td>
                        <td className="py-3 ps-4 fw-500">
                          <div className="td-text">{user.date}</div>
                        </td>
                        <td className="py-3 ps-4 fw-500">
                          <div className="td-text">
                            {quantityFormat(user.hours)}
                          </div>
                        </td>
                        <td className="py-3 ps-4 fw-500">{user.description}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <Footer />
          </div>
        )}
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";

type HomeState = {
  isLoading: boolean;
  missingTime: {
    missingTime: boolean;
    hours: number;
    startDate: string;
    endDate: string;
  };
};

const initialState: HomeState = {
  isLoading: true,
  missingTime: {
    missingTime: false,
    hours: 0,
    startDate: "",
    endDate: "",
  },
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadHomeInfo: (state, action) => {
      state.missingTime = action.payload.data;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, loadHomeInfo } = homeSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.home.isLoading;
};

export const MissingTime = (state: any) => {
  return state.home.missingTime;
};

export default homeSlice.reducer;

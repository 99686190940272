import { useSelector } from "react-redux";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import {
  SelectFilters,
  SelectInitialStateFilters,
} from "../redux/reducers/timeConfirmationReport.reducer";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { hasTimeAdminAccess } from "../utils/timesUtils";

export const useTimesConfirmationReport = () => {
  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const filters = useSelector(SelectFilters);
  const initialStatefilters = useSelector(SelectInitialStateFilters);
  const { userRolesTimesModule, asuiteTimesPermissions, isLoadingUserInfo } =
    useSelector(actualUIState);
  // times module admin
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);

  return {
    MetaTimesInfo,
    filters,
    initialStatefilters,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isLoadingUserInfo,
    isTimeAdmin,
  };
};

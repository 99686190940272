import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IsLoading,
  setLoading,
  MissingTime,
  loadHomeInfo,
} from "../redux/reducers/home.reducer";
import { TimesheetsService } from "../services/timesheets";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";

export const useHome = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(IsLoading);
  const missingTime = useSelector(MissingTime);
  const { activeUserName } = useSelector(selectUserRoles);

  const loadHomePageInfo = useCallback(async () => {
    dispatch(setLoading(true));
    const missingTime = await TimesheetsService.getOwnMissingTime();
    dispatch(loadHomeInfo(missingTime));
  }, [dispatch]);

  useEffect(() => {
    loadHomePageInfo();
  }, [loadHomePageInfo]);

  return { activeUserName, isLoading, missingTime };
};

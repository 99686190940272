import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { checkDataIsLoaded, sendNullIfEmpty } from "../utils/timesUtils";
import eventHandler from "../../SharedModule/utils/eventHandler";
import {
  LAST_MONTH,
  LAST_PAY_PERIOD,
  ROUTE_TIME_CONFIRMATION_REPORT,
  ROUTE_TIME_CONFIRMATION_RESULTS,
  ROUTE_TIMESHEETS_REPORTS,
  THIS_MONTH,
  THIS_PAY_PERIOD,
  TIME_ADMIN_ACCESS,
  YEAR_TO_DATE,
} from "../../SharedModule/utils/constants";
import { verifyLinkAccess } from "../../SharedModule/utils/accessUtils";
import { ForbiddenPage } from "../../SharedModule/pages/ForbiddenPage";
import { Spinner } from "../../SharedModule/components/Spinner";
import { BiLeftArrowAlt, BiInfoCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { Calendar } from "../../SharedModule/components/Calendar";
import { Tooltip, Zoom } from "@mui/material";
import { Select } from "../../SharedModule/components/Select";
import Footer from "../../SharedModule/components/Footer";
import { Radio } from "../../SharedModule/components/Radio";
import { useTimesConfirmationReport } from "../hooks/useTimesConfirmationReport";
import {
  LAST_MONTHS_END,
  LAST_MONTHS_START,
  THIS_YEAR_START,
} from "../../SharedModule/utils/dateUtils";
import {
  keepFilters,
  setFilters,
} from "../redux/reducers/timeConfirmationReport.reducer";

export const TimesConfirmationReportsPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    MetaTimesInfo,
    filters,
    initialStatefilters,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isTimeAdmin,
    isLoadingUserInfo,
  } = useTimesConfirmationReport();

  const [selectedUsa, setSelectedUsa] = useState(!filters.allCountries);

  useEffect(() => {
    document.title = "The Hub - Time Confirmation Report";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    dispatch(setActiveTab(ROUTE_TIME_CONFIRMATION_REPORT));

    return () => {
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_TIME_CONFIRMATION_RESULTS) {
        dispatch(keepFilters(false));
      }
    };
  }, [trackPageView, isTimeAdmin]);

  const [timeConfirmationFilters, setTimeConfirmationFilters] = useState({
    allCountries: filters.allCountries,
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCentersIDs:
      MetaTimesInfo.costCentersApprovalTime?.length === 1
        ? [MetaTimesInfo.costCentersApprovalTime[0]]
        : filters.costCentersIDs,
  });

  const updateDates = (option: string) => {
    switch (option) {
      case LAST_PAY_PERIOD:
        setTimeConfirmationFilters({
          ...timeConfirmationFilters,
          startDate: MetaTimesInfo.payPeriods?.previous.startDate,
          endDate: MetaTimesInfo.payPeriods?.previous.endDate,
        });
        break;
      case THIS_PAY_PERIOD:
        setTimeConfirmationFilters({
          ...timeConfirmationFilters,
          startDate: MetaTimesInfo.payPeriods?.current.startDate,
          endDate: MetaTimesInfo.payPeriods?.current.endDate,
        });
        break;
      case THIS_MONTH:
        setTimeConfirmationFilters({
          ...timeConfirmationFilters,
          startDate: initialStatefilters.startDate,
          endDate: initialStatefilters.endDate,
        });
        break;
      case LAST_MONTH:
        setTimeConfirmationFilters({
          ...timeConfirmationFilters,
          startDate: LAST_MONTHS_START.toString(),
          endDate: LAST_MONTHS_END.toString(),
        });
        break;
      case YEAR_TO_DATE:
        setTimeConfirmationFilters({
          ...timeConfirmationFilters,
          startDate: THIS_YEAR_START.toString(),
          endDate: initialStatefilters.endDate,
        });
        break;
      default:
        break;
    }
  };

  const changeRadioButton = (value: boolean) => {
    setSelectedUsa(!value);
    setTimeConfirmationFilters({
      ...timeConfirmationFilters,
      allCountries: value,
    });
  };

  const runQuery = () => {
    dispatch(setFilters(timeConfirmationFilters));
    navigate(ROUTE_TIME_CONFIRMATION_RESULTS);
  };

  return (
    <>
      {isLoadingUserInfo && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingUserInfo &&
        !verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && <ForbiddenPage />}
      {!isLoadingUserInfo &&
        verifyLinkAccess(
          true,
          false,
          TIME_ADMIN_ACCESS,
          [],
          userRolesTimesModule,
          asuiteTimesPermissions
        ) && (
          <div className="content">
            <div className="mt-2 button-header">
              <Link
                className="back-button-container text-decoration-none"
                to={ROUTE_TIMESHEETS_REPORTS}
              >
                <BiLeftArrowAlt className="back-button-arrow" />
                <span className="back-button-text">Back to Time reports</span>
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <h2 className="flex-fill">Time Confirmation</h2>
            </div>

            <form className="card entry-hour-box-background p-4 mt-3">
              <div className="row justify-content-center">
                <label className="col-sm-3 col-form-label">
                  People Located in
                </label>
                <div className="col-sm-5">
                  <div className="w-50 d-inline-block">
                    <Radio
                      label="All countries"
                      value={!selectedUsa}
                      checked={!selectedUsa}
                      onChange={() => changeRadioButton(true)}
                    />
                  </div>
                  <div className="w-50 d-inline-block">
                    <Radio
                      label="Usa only"
                      value={selectedUsa}
                      checked={selectedUsa}
                      onChange={() => changeRadioButton(false)}
                    />
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <label className="col-sm-3 col-form-label">Time Frame</label>
                <div className="col-sm-5">
                  <div className="d-flex">
                    <div className="position-relative">
                      <Calendar
                        date={timeConfirmationFilters.startDate}
                        onChange={(newDate: string) =>
                          setTimeConfirmationFilters({
                            ...timeConfirmationFilters,
                            startDate: newDate,
                          })
                        }
                      />
                    </div>
                    <div className="position-relative ms-5">
                      <Calendar
                        date={timeConfirmationFilters.endDate}
                        onChange={(newDate: string) =>
                          setTimeConfirmationFilters({
                            ...timeConfirmationFilters,
                            endDate: newDate,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="mt-2 offset-sm-5 col-sm-7">
                  {selectedUsa && (
                    <div
                      className="btn button-secondary me-2"
                      onClick={() => updateDates(LAST_PAY_PERIOD)}
                    >
                      {LAST_PAY_PERIOD}
                    </div>
                  )}
                  {selectedUsa && (
                    <div
                      className="btn button-secondary me-2"
                      onClick={() => updateDates(THIS_PAY_PERIOD)}
                    >
                      {THIS_PAY_PERIOD}
                    </div>
                  )}
                  <div
                    className="btn button-secondary me-2"
                    onClick={() => updateDates(THIS_MONTH)}
                  >
                    {THIS_MONTH}
                  </div>
                  <div
                    className="btn button-secondary me-2"
                    onClick={() => updateDates(LAST_MONTH)}
                  >
                    {LAST_MONTH}
                  </div>
                  <div
                    className="btn button-secondary"
                    onClick={() => updateDates(YEAR_TO_DATE)}
                  >
                    {YEAR_TO_DATE}
                  </div>
                </div>
              </div>

              <div className="row mt-5 justify-content-center">
                <label className="col-sm-3 col-form-label">
                  Cost Center
                  <Tooltip
                    title={
                      <b>
                        Time for users that are assigned to this Cost Center
                      </b>
                    }
                    placement="right"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <span>
                      <BiInfoCircle className="disc-icon ms-1" />
                    </span>
                  </Tooltip>
                </label>
                <div className="col-sm-5">
                  <Select
                    isMulti
                    placeholder="All Cost Centers"
                    options={MetaTimesInfo.costCentersApprovalTime}
                    value={timeConfirmationFilters.costCentersIDs}
                    onChange={(costCentersIDs) =>
                      setTimeConfirmationFilters({
                        ...timeConfirmationFilters,
                        costCentersIDs: sendNullIfEmpty(costCentersIDs),
                      })
                    }
                    isDisabled={false}
                  />
                </div>
              </div>

              <hr className="separator" />
              <div className="mt-4 footer-filter justify-content-end">
                <button className="btn btn-primary" onClick={() => runQuery()}>
                  Run Report
                </button>
              </div>
            </form>
            <Footer />
          </div>
        )}
    </>
  );
};

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";

type WhoisOutReportState = {
  isLoading: boolean;
  keepFilters: boolean;
  filters: {
    groupByDate: boolean;
    costCenter: Array<any> | null;
    project: Array<any> | null;
    timeFrame: any;
  };
  whoIsOutUserList: any;
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date" | "number";
  };
};

const initialState: WhoisOutReportState = {
  isLoading: true,
  keepFilters: false,
  filters: {
    groupByDate: true,
    costCenter: null,
    project: null,
    timeFrame: null,
  },
  whoIsOutUserList: null,
  orderedBy: {
    orderBy: "userName",
    criteria: "asc",
    dataType: "string",
  },
};

export const whoisOutReportSlice = createSlice({
  name: "whoisOutReport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    loadWhoIsOutInfo: (state, action) => {
      state.whoIsOutUserList = action.payload;
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date" | "number";
        criteria?: "asc" | "desc";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: action.payload.criteria ? action.payload.criteria : "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria = action.payload.criteria
          ? action.payload.criteria
          : state.orderedBy.criteria === "asc"
          ? "desc"
          : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setFilters, changeOrder, loadWhoIsOutInfo } =
  whoisOutReportSlice.actions;

export const SelectFilters = (state: any) => {
  return state.whoisOutReport.filters;
};

export const GetWhoIsOutUserList = (state: any) => {
  return state.whoisOutReport.whoIsOutUserList;
};

export const SelectWhoIsOutUsersList = ({
  whoisOutReport,
}: {
  whoisOutReport: WhoisOutReportState;
}) => {
  return {
    whoIsOutUserList: whoisOutReport.whoIsOutUserList,
  };
};

export const selectOrderCriteria = ({
  whoisOutReport,
}: {
  whoisOutReport: WhoisOutReportState;
}) => whoisOutReport.orderedBy;

export const selectOrderedBy = createSelector(
  [SelectWhoIsOutUsersList, selectOrderCriteria],
  (whoisOutReport, orderCriteria) => {
    return {
      timeConfirmationUsersList:
        (whoisOutReport.whoIsOutUserList &&
          whoisOutReport.whoIsOutUserList.length > 0 &&
          sortDataBy(
            whoisOutReport.whoIsOutUserList,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null,
    };
  }
);

export default whoisOutReportSlice.reducer;

import { useDispatch, useSelector } from "react-redux";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { hasTimeAdminAccess } from "../utils/timesUtils";
import { GetWhoIsOutUserList, loadWhoIsOutInfo, SelectFilters, setLoading } from "../redux/reducers/whoIsOutReport.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";

export const useWhoIsOutReport = () => {
  const dispatch = useDispatch();

  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const filters = useSelector(SelectFilters);
  const { userRolesTimesModule, asuiteTimesPermissions, isLoadingUserInfo } =
    useSelector(actualUIState);
  // times module admin
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const whoIsOutUserList = useSelector(GetWhoIsOutUserList);

  const loadWhoIdOutResults = useCallback(async () => {
    dispatch(setLoading(true));
    const results = await TimesheetsService.getWhoisOutInfo(filters);
    dispatch(loadWhoIsOutInfo(results));
  }, [dispatch, filters]);

  useEffect(() => {
    loadWhoIdOutResults();
  }, [loadWhoIdOutResults]);

  return {
    MetaTimesInfo,
    whoIsOutUserList,
    filters,
    userRolesTimesModule,
    asuiteTimesPermissions,
    isLoadingUserInfo,
    isTimeAdmin,
  };
};
